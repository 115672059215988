/* 
.scrollHalf { position: relative}

.scrollHalf__grupo {  transition: all 200ms ease-in-out; }
.scrollHalf__grupo__imagen { overflow: hidden; }
.scrollHalf__grupo__contenido { padding: 3rem;     }


.scrollHalf__grupo[data-scroll="out"] { opacity: 0;}
.scrollHalf__grupo[data-scroll="in"] { opacity: calc(var(--visible-y)*1.9);  }  


@media screen and (min-width:900px) {
  .scrollHalf__grupo { min-height: 100vh;}
  .scrollHalf__grupo__imagen { position: fixed; top: 0; right: 0; width: 50vw;}
  .scrollHalf__grupo__imagen img {  min-height: 100vh;  min-width: 50vw; }
  .scrollHalf__grupo__contenido { margin: 0 3vw 0 52vw;}
} */

/*  */

/*
section.about-journey-section {
	display: flex;
	min-height: 100vh;
    background: #1D273B;
}

 .aboutjourney-section .padding-left {
    padding-left: 10%;
}

.bg1 {
    background-image: url('https://cdn.pixabay.com/photo/2017/02/07/11/45/eagle-2045655_960_720.jpg');
    background: url('../images/journeyimg.webp');
}

.bg2 {
    background-image: url('https://cdn.pixabay.com/photo/2012/06/19/10/32/owl-50267_960_720.jpg');
}

.bg3 {
    background-image: url('https://cdn.pixabay.com/photo/2017/02/07/11/45/eagle-2045655_960_720.jpg');
}

.right,
.left {
    flex: 1;
}

.right .inner {
	height: 100vh;
	display: block;
}

.left .inner {
	padding: 40px 100px;
	font-size: 22px;
}

.right .inner.sticky {
	position: sticky;
	top: 0;
}

.right .inner.sticky .bg {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
} */

.section {
	display: flex;
	min-height: 100vh;
}

.section .right,
.section .left {
	flex: 1;
}

.section .right .inner {
	height: 100vh;
	display: block;
}

.section .left .inner {
	padding: 40px 100px;
	font-size: 22px;
}

.section .right .inner.sticky {
	position: sticky;
	top: 0;
}

.section .bg1 {
    background: url('../images/journeyimg.webp');
}

.section .bg2 {
    background: url('../images/Corporate-governance.webp');
}

.section .bg3 {
    background: url('../images/Successionchess.webp');
}

.section .right .inner.sticky .bg {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
}

.section .left {
    background: #1D273B;
}

.section .left .text-div {
    background: #EDF1F5;
    padding: 0px;
    margin-left: 20%;
    padding: 30px;
    min-height: 100vh;
}


.section .left .text-div .aboutjourney-heading {
    color: #182D4D;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    /* line-height: normal; */
    text-align: left;
    padding-top: 20px;
}

.section .left .text-div .aboutjourney-sub-heading {
    color: #182D4D;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    /* line-height: normal; */
    transform: translateY(-20px) !important;
    padding-top: 20px;
}

.journey .section .left .text-div .aboutjourney-para {
    color: #292D35;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 40px;
}
.journey.ar .section .left .text-div .aboutjourney-heading {
    direction: rtl;
    text-align: right;
}

.journey.ar .section .left .text-div ul {
    list-style: none;
}


.journey.ar section  .aboutjourney-para,
.journey.ar .section .left .text-div ul li ,
.journey.ar .section .left .text-div .aboutjourney-para {
    direction: rtl;
    text-align: right;
}

.journey .section .left .text-div ul li {
    color: #4F545C;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.journey.ar .left {
    order: 2;
}

.journey.ar .section .left .text-div {
    margin-left: 0;
    margin-right: 20%;
}


/* Mobile version */
@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 
.journey {
    background: #1D273B;    
    padding-left: 25px;
}

.journey.mobile.ar {
    direction: rtl;
    padding-left: 0px;
    padding-right: 25px;
}

.journey .bg1 {
    background: url('../images/journeyimg-mb.webp');
}

.journey .bg2 {
    background: url('../images/Corporate-governance-mb.webp');
}

.journey .bg3 {
    background: url('../images/Successionchess-mb.webp');
}

.journey .bg {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
    max-width: 540px;
    margin: auto;
}

.journey section {
    padding: 0px 30px 15px 15px;
}

.journey.mobile.ar section {
    padding: 0px 15px 15px 30px;
}

.journey section .heading-bg {
    background: #EDF1F5;
    background: #61768c;
    width: 70%;
    height: 77px;
    margin-left: -15px;
    padding-left: 15px;
    margin-bottom: 15px;
}

.journey.mobile.ar section .heading-bg {
    padding-left: 0px;
    padding-right: 15px;
}

.journey section .aboutjourney-heading {
    color: #FFF;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    padding-top:5px;
}

.journey.mobile.ar section .aboutjourney-heading {
    text-align: right;    
}

.journey section  .aboutjourney-sub-heading {
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    text-align: left;
    line-height: normal;
}

.journey section  .aboutjourney-para {
    color: #FFF;
    text-align: justify;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%; 
}


.journey.mobile.ar section  .aboutjourney-para {
    text-align: justify;
}



.journey section ul li {
    color: #FFF;
    font-size: 16px;
    line-height: 130%; 
}

.journey.ar section ul {
    list-style: none;
    padding-right: 0;
}

}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 
    
    .journey.desktop {
        display: none;
    }

    .journey.mobile {
        display: block;
    }


    .journey {
        background: #1D273B;    
        padding-left: 25px;
    }
    
    .journey.mobile.ar {
        direction: rtl;
        padding-left: 0px;
        padding-right: 25px;
    }

    
    .journey .bg1 {
        background: url('../images/journeyimg-mb.webp');
    }
    
    .journey .bg2 {
        background: url('../images/Corporate-governance-mb.webp');
    }
    
    .journey .bg3 {
        background: url('../images/Successionchess-mb.webp');
    }
    
    .journey  .bg {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        max-width: 720px;
        margin: auto;
    }
    
    .journey section {
        padding: 0px 30px 15px 15px;
    }
        
    .journey.mobile.ar section {
        padding: 0px 15px 15px 30px;
    }
    
    .journey section .heading-bg {
        background: #EDF1F5;
        background: #61768c;
        /* mix-blend-mode: soft-light; */
        width: 65%;
        height: 77px;
        margin-left: -15px;
        padding-left: 15px;
        margin-bottom: 15px;
    }

    .journey.mobile.ar section .heading-bg {
        padding-left: 0px;
        padding-right: 15px;
    }
    
    .journey section .aboutjourney-heading {
        color: #FFF;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
        text-align: left;
    }
    
    .journey.mobile.ar section .aboutjourney-heading {
        text-align: right;    
    }

    .journey section  .aboutjourney-sub-heading {
        color: #FFF;
        font-size: 24px;
        font-weight: 700;
        text-align: left;
        line-height: normal;
    }
    
    .journey section  .aboutjourney-para {
        color: #FFF;
        text-align: justify;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%; 
    }
    
    .journey section ul li {
        color: #FFF;
        font-size: 16px;
        line-height: 130%; 
    }

    .journey.ar section ul {
        list-style: none;
        padding-right: 0;
    }
    
    }