.aboutheader-section{
    background-image: url(../images/about-header-bg.webp);
    background-repeat: no-repeat;
    background-size: cover !important;
    height: auto;
    min-height: 100vh;
    /* overflow-x: hidden; */


}

.aboutheader-section .about-heading {
    color: #FFF;
    text-align: center;
    font-size: 60px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
    text-transform: uppercase;
    position: absolute;
    top: 40% !important;
    left: 0%;
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {
    .aboutheader-section .about-heading {
        font-size: 36px;
        line-height: normal;
      }
}

@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {
    .aboutheader-section .about-heading {
        font-size: 36px;
        line-height: normal;
      }
}