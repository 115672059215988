.menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
}

.menu.ar {
    direction: rtl;
}

.menu.ar .dropdown-toggle::after {
    margin-right: 0.255em;
}

.menu .navbar-brand img{
    width: 250px;
    height: auto;
    position: relative;
}

.menu nav.navbar.navbar-expand-md {
    padding: 15px 50px 15px;
}

.offcanvas-header .btn-close {
    background: url('../images/x.webp') no-repeat;
}

.menu .dropdown-menu.show {
    /* border: 1px solid #797979; */
    background: rgba(0, 0, 0, 0.30);
    /* border-radius: 0px; */
    margin: 0px;
    padding: 0px;
}

.menu .navbar-nav {
    gap: 0px 25px;
}

.menu .dropdown-menu.show .dropdown-item {
    color: #FFF;
    font-size: 14px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid rgba(0, 0, 0, 0.30);;
    padding: 10px 15px;
}
.menu .dropdown1{
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #797979;
    background: rgba(0, 0, 0, 0.30);
}
.menu .dropdown2{
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #797979;
    background: rgba(0, 0, 0, 0.30);
}
.menu .dropdown-menu.show .dropdown-item:hover {
    color: #ffffff;
    background:transparent;
}

.menu .navbar-nav a {
    color: #FFF;
    font-size: 14px;
    font-family: Vazirmatn;
    font-weight: 400;
    /* justify-content: space-between; */
}

/* .menu .dropdown-menu .lang-dropdown{
 
} */

/* .menu .select-lang {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(0, 0, 0, 0.30);
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px;
} */

/* .menu .select-lang select{
    border: 1px solid transparent;
    background: transparent;
    color: #FFF;
    overflow: auto;
} */

.menu .select-lang {
    position: relative;
}

.menu .select-lang select {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(0, 0, 0, 0.30);
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px 5px 5px 30px;
    padding: 10px 10px 5px 35px;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    cursor: pointer;
}

.menu .select-lang::before {
    content: "-";
    background: url('../images/globe.webp') no-repeat;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 10px;
    width: 16px;
    height: 16px;
    z-index: 2;
}

.menu .select-lang select option 
.menu .select-lang select option:hover {
    background: rgba(0, 0, 0, 0.30);
    max-width: 200px;
    padding-left: 50px;
    padding-right: 70px;
    color: #FFF;
    font-family: Vazirmatn;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.menu .select-lang select:focus-visible {
    outline: none;
}

.ar.offcanvas.offcanvas-end.show {
    direction: rtl;
}

.offcanvas.offcanvas-end.show .offcanvas-body .select-lang {
    position: relative;
}

.offcanvas.offcanvas-end.show .offcanvas-body .select-lang select {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(0, 0, 0, 0.30);
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px 5px 5px 30px;
    appearance: none;
    -webkit-appearance: none;
    position: relative;
    cursor: pointer;
}


.offcanvas.offcanvas-end.show .offcanvas-body .select-lang.ar select {
    padding: 10px 10px 5px 30px;
}

.offcanvas.offcanvas-end.show .offcanvas-body .select-lang::before {
    content: "-";
    background: url('../images/globe.webp') no-repeat;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 10px;
    width: 16px;
    height: 16px;
    z-index: 2;
}

.offcanvas.offcanvas-end.show .offcanvas-body .ar.select-lang::before {
    right: 60px;
}

.offcanvas.offcanvas-end.show .offcanvas-body .select-lang select option ,
.offcanvas.offcanvas-end.show .offcanvas-body .select-lang select option:hover {
    background: rgba(0, 0, 0, 0.30);
    max-width: 200px;
    padding-left: 50px;
    padding-right: 70px;
    color: #FFF;
    font-family: Vazirmatn;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.offcanvas.offcanvas-end.show .offcanvas-body .select-lang select:focus-visible {
    outline: none;
}


@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {
    .menu .navbar-brand img{
        width: 130px;
    }

    .menu nav.navbar.navbar-expand-md {
        padding: 10px 10px 15px;
    }

    .navbar-toggler-icon {
        width: 27px;
        height: 27px;
    }

    .offcanvas-title img {
        max-width: 150px;
    }

    #offcanvasNavbar-expand-md {
        background: #28364D;
    }

    .offcanvas-body a.nav-link {
        color: #FFF;
        font-family: Vazirmatn;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 227.778%; 
    }

    .navbar-toggler {
        border: none;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .dropdown-toggle::after {
        position: absolute;
        top: 20px;
        right: 0;
    }

    .offcanvas-body .dropdown-menu {
        position: static;
        background: transparent;
        border-left: 1px;
        border-right: 1px;
        margin-left: 30px;
    }

    .offcanvas-body .dropdown-menu .dropdown-item a {
        color: #FFF;
        font-family: Vazirmatn;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 41px; 
    }

    .offcanvas-body .dropdown-menu a.nav-link {
        font-size: 18px;
        line-height: 227.778%;    
    }

    .offcanvas-body  .nav-link.active,
     .offcanvas-body  .nav-link.show {
        color: #ffffff;
     }
    
     .offcanvas-body .menu-address-box {
        margin-top: 30px;
        padding-top: 20px; 
        padding-bottom: 20px; 
        border-top: 1px solid #48576C;
        border-bottom: 1px solid #48576C;
     }

     .offcanvas-body .menu-address-box .address-paragraph a {
        color: #94A1B6;
        font-family: Vazirmatn;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 183.333%; 
     }

     .offcanvas-body .menu-copyright {
        padding-top: 30px;
        color: #FFF;
        font-family: Nunito;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
     }

     .offcanvas.offcanvas-end.show .offcanvas-body .dropdown-item:focus, 
     .offcanvas.offcanvas-end.show .offcanvas-body .dropdown-item:hover ,
     .offcanvas.offcanvas-end.show .dropdown-item.active{
        color: #ffffff;
        background: transparent;
     }

     
.menu .select-lang {
    position: relative;
    margin-top: 5px;
}

.menu .select-lang select {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(0, 0, 0, 0.30);
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px 5px 5px 30px;
    position: relative;
    cursor: pointer;
}

.menu .select-lang.mobile select {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.30);
    background: rgba(0, 0, 0, 0.30);
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px 2px 2px 25px;
    position: relative;
    cursor: pointer;
}

.menu .select-lang.mobile::before {
    left: 5px;
}

}


@media only screen  and (min-device-width : 768px) {
 
 
    /* .menu .navbar-brand img{
        width: 130px;
    }

    .menu nav.navbar.navbar-expand-md {
        padding: 10px 25px 15px;
    }

    .navbar-toggler-icon {
        width: 27px;
        height: 27px;
    }
 */
    .offcanvas-title img {
        max-width: 150px;
    }

    #offcanvasNavbar-expand-md {
        background: #28364D;
    }

    .offcanvas.offcanvas-end.show .offcanvas-body a.nav-link {
        color: #FFF;
        font-family: Vazirmatn;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 227.778%; 
    }

    .navbar-toggler {
        border: none;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    /* .dropdown-toggle::after {
        position: absolute;
        top: 20px;
        right: 0;
    } */

 
     .offcanvas.offcanvas-end.show .offcanvas-body .menu-address-box {
        margin-top: 30px;
        padding-top: 20px; 
        padding-bottom: 20px; 
        border-top: 1px solid #48576C;
        border-bottom: 1px solid #48576C;
     }

     .offcanvas.offcanvas-end.show .offcanvas-body .menu-address-box .address-paragraph a {
        color: #94A1B6;
        font-family: Vazirmatn;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 183.333%; 
     }

     .offcanvas.offcanvas-end.show .offcanvas-body .menu-copyright {
        padding-top: 30px;
        color: #FFF;
        font-family: Nunito;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
     }

     .offcanvas.offcanvas-end.show .offcanvas-body .dropdown-menu {
        position: static;
        background: transparent;
        border-left: 1px;
        border-right: 1px;
        margin-left: 30px;
    }

    .offcanvas.offcanvas-end.show .offcanvas-body .dropdown-menu .dropdown-item a {
        color: #FFF;
        font-family: Vazirmatn;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 41px; 
    }

    .offcanvas.offcanvas-end.show .offcanvas-body .dropdown-menu a.nav-link {
        font-size: 18px;
        line-height: 227.778%;    
    }


    .offcanvas.offcanvas-end.show .offcanvas-body  .nav-link.active,
    .offcanvas.offcanvas-end.show .offcanvas-body  .nav-link.show{
        color: #ffffff;
     }

     .offcanvas.offcanvas-end.show .offcanvas-body .dropdown-item:focus, 
     .offcanvas.offcanvas-end.show .offcanvas-body .dropdown-item:hover ,
     .offcanvas.offcanvas-end.show .dropdown-item.active {
        color: #ffffff;
        background: transparent;
     }
     
    
     .offcanvas.offcanvas-end.show .offcanvas-body .menu-address-box {
        margin-top: 30px;
        padding-top: 20px; 
        padding-bottom: 20px; 
        border-top: 1px solid #48576C;
        border-bottom: 1px solid #48576C;
     }
     .offcanvas.offcanvas-end.show .offcanvas-body .menu-address-box,
     .offcanvas.offcanvas-end.show .offcanvas-body .menu-copyright {
        display: block;
     }

     .offcanvas.offcanvas-end .offcanvas-body .menu-address-box .address-paragraph a {
        color: #94A1B6;
        font-family: Vazirmatn;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 183.333%; 
     }

     .offcanvas.offcanvas-end .offcanvas-body .menu-copyright {
        padding-top: 30px;
        color: #FFF;
        font-family: Nunito;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
     }
}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {  
    .menu .mobile {
        display: none;
    }

    .menu nav.navbar.navbar-expand-md {
        padding: 5px 10px 5px;
    }

    .menu .navbar-nav {
        gap: 0px 15px;
        align-items: baseline;
    }

    .menu .navbar-brand img {
        width: 150px;    
    }

    .menu .navbar-nav a {
        font-size: 12px;
    }
}

@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  {  
    .menu .navbar-brand img {
        width: 200px;    
    }

    .menu .navbar-nav a {
        font-size: 13px;
    }
}