.career-contact-section {
    background: #EDF1F5;
    position: relative;
    overflow: hidden;
}

/* .career-contact-section.ar {
    direction: rtl;
} */

.career-contact-section .career-contact-heading {
    color: #1D273B;
    text-align: center;
    font-size: 40px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 700;
    line-height: 90px;
    /* text-transform: capitalize; */
}

.career-contact-section .submit-btn {
    border: 1px solid #A12222;
    background: #D2433D;
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    width: 100%;
    margin-right: 0px;
    height: 64px;
}

 .close-btn {
    border: 1px solid #A12222;
    background: #D2433D;
    text-align: center;
    margin: auto;
    width: 162px;
    height: 44px;

    color: #FFF;
    font-family: Vazirmatn;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 222.222%;
}

input, option, select, textarea {
    font-family: Vazirmatn !important;
}

input[type="tel"] {
    padding: 16.5px 14px;
    background: none;  
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    border-radius: 4px;
    box-shadow: none;
    border: 1.2px solid #B4BEC8;
    outline: none;
}

.career-contact-section .errormsg {
    color: red;
    font-weight: 600;
   text-align: left;
   margin-left: 10px;

}
.career-contact-section textarea.w-100.form-control,.career-contact-section .form-control{
    background: #EDF1F5;
    border-radius: 4px;
    border: 1.2px solid #B4BEC8;
    border-radius: 4px;
    border: 1.2px solid #B4BEC8;
}
textarea.w-100.form-control {
    /* margin-left: 7px; */
    margin-top: 10px;
}
.career-contact-section .marginleft{
    margin-left: 7px;
}


.career-contact-section .topleft {
    position: absolute;
    left: 50px;
    top: 0px;
}

.career-contact-section .bottomright {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

textarea  {
    padding-left: 10px;
    padding-top: 10px;
}

.text-left {
    text-align: left;
}


.modal-content {
    border-radius: 0px;
}

.modal-content h4 {
    color: #000;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.modal-content p {
    color: #000;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 
    .career-contact-section .topleft {
        left: 0;
    }

    .career-contact-section .career-contact-heading {
        font-size: 24px;
    }

    .career-contact-section .submit-btn { 
        width: 244px;
        height: 64px;
    }
}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 
    .career-contact-section .desktop {
        display: none;
    }
    .career-contact-section .mobile {
        display: block;
    }

    .career-contact-section .col-md-4 {
        width: 100%;
        max-width: 720px;
        margin: auto;
    }

    .career-contact-section .career-contact-heading {
        font-size: 24px;
    }

    .career-contact-section .submit-btn { 
        width: 244px;
        height: 64px;
    }
}


@media only screen   and (min-device-width : 1023px)  and (max-device-width : 1280px)  { 
    .career-contact-section .topleft img,
    .career-contact-section .bottomright img {
        width: 50%;
    }

    .career-contact-section .topleft {
        text-align: left;
    }
    
    .career-contact-section .bottomright {
        text-align: right;
    }
}


@media only screen   and (min-device-width : 1281px)  and (max-device-width : 1360px)  {
    .career-contact-section .topleft img,
    .career-contact-section .bottomright img {
        width: 60%;
    }

    .career-contact-section .topleft {
        text-align: left;
    }
    
    .career-contact-section .bottomright {
        text-align: right;
    }
}


@media only screen   and (min-device-width : 1361px)  and (max-device-width : 1600px)  {
    .career-contact-section .topleft img,
    .career-contact-section .bottomright img {
        width: 70%;
    }

    .career-contact-section .topleft {
        text-align: left;
    }
    
    .career-contact-section .bottomright {
        text-align: right;
    }
}