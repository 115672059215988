.investment-port-section {
    min-height: 400px;
    width: 100%;
    background: #EDF1F5;
    position: relative;
}

.investment-port-section.ar {
    direction: rtl;
}

.investment-port-section.text-box {
    color: #FFFFFF;
    width: 100%;
    margin: auto !important;
    max-width: 1200px;
    padding: 60px;
    z-index: 4;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(180deg, #1C263A 0%, #253046 100%);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background: #5E83AE;
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.25);
}

.investment-port-section .text-box img {
    width: 100%;
}

.investment-port-section .topright {
    position: absolute;
    top: 0;
    right: 10px;
}

.investment-port-section .bottomleft {
    position: absolute;
    bottom: 0;
    left: 0px;
}

.investment-port-section .text-box h2 {
    color: #FFF;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-align: left;
}

.investment-port-section .text-box p {
    color: #FFF;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    ;
}

.investment-port-section.ar .text-box h2,
.investment-port-section.ar .text-box p {
    text-align: right;
}

.investment-port-section .img-para {

    font-family: 'Vazirmatn';
    font-style: normal;
    font-weight: 400;
    font-size: 22px !important;
    line-height: 34px;
    padding-top: 40px;
    text-align: justify !important;
}

.investment-section {
    min-height: 400px;
    width: 100%;
    background: #EDF1F5;
    position: relative;
}

.investment-port-section .text-box {
    color: #FFFFFF;
    width: 100%;
    margin: auto !important;
    max-width: 1200px;
    padding: 60px;
    z-index: 4;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    /* background: linear-gradient(180deg, #1C263A 0%, #253046 100%);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25); */
    border-radius: 20px;
    background: #5E83AE;
    background: linear-gradient(180deg, #5E83AE 0%, #446FA0 52.08%, #507CAE 100%);
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.25);
}

.investment-port-section .text-box img {
    width: 100%;
}

.investment-port-section.ar .text-box .heading-img {
    text-align: right;
}
.investment-port-section.ar .text-box img {
    transform: scaleX(-1);
}

.investment-port-section.ar .text-box .heading-img h2 {
    right: 54%;
}


.topright {
    position: absolute;
    top: 0;
    right: 10px;
}

.bottomleft {
    position: absolute;
    bottom: 0;
    left: 0px;
}

.investment-port-section .text-box h2 {
    color: #FFF;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 52px */
    text-align: left;
}

.investment-port-section.text-box p {
    color: #FFF;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    ;
}

.investment-port-section .img-para {

    font-family: 'Vazirmatn';
    font-style: normal;
    font-weight: 400;
    font-size: 22px !important;
    line-height: 34px;
    padding-top: 40px;
    text-align: justify !important;
}

/* .investment-section {
    width: 100%;
    background: #1D273B;
    position: relative;
    padding-top: 350px;
} */


/* investment portfolio */

.investment-portfolio-section {
    width: 100%;
    background: #1D273B;
    position: relative;
    padding-top: 300px;
    padding-bottom: 150px;
}

.investment-portfolio-section.ar {
    direction: rtl;
}

.investment-portfolio-section .text-box {
    margin-top: 3rem;
}

.investment-portfolio-section .portleft1 {
    position: absolute;
    top: 10%;
    left: 0;   
}

.investment-portfolio-section .portright1 {
    position: absolute;
    top: 40%;
    right: 0;   
}

.investment-portfolio-section .portleft2 {
    position: absolute;
    top: 63%;
    left: 0;   
}

.investment-portfolio-section .portright2 {
    position: absolute;
    bottom:0;
    right: 0;   
}



.investment-portfolio-section .header h2 {
    color: #FFF;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.investment-portfolio-section .header p {
    color: #C8C8C8;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
}
/* 
.investment-portfolio-section .img-box {
    display: flex;
    justify-content: space-between;
    background-image: url(../images/ipbgimg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    padding: 15px;
    margin: 7px 0px;
    text-align: left;
}

.investment-portfolio-section .img-box h2 {
    color: #000;
    font-family: Vazirmatn;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.investment-portfolio-section .img-box h3 {
    color: #1D273B;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.investment-portfolio-section .img-box p {
    color: #8F90A6;
    text-align: justify;
    font-family: DM Sans;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.investment-portfolio-section .img-box img {
    width: 100%;
    border-radius: 4px;
}

.investment-portfolio-section .img-box .overlay-text {
    color: #D1D2DF;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    top: 80%;

}


.investment-portfolio-section .img-box .overlay-text3 {
    position: absolute;
    top: 70%;

}

.investment-portfolio-section .img-box .overlay-text4 {
    position: absolute;
    top: 40%;

}

.investment-portfolio-section .img-box .potn-reltv,
.investment-portfolio-section .img-box .row {
    position: relative;
    display: flex;
    justify-content: center;
}

.investment-portfolio-section .img-box h2:after {
    content: "";
    width: 126px;
    height: 2px;
    background: #D2433D;
    position: absolute;
    top: 23%;
    left: 50%;
    transform: translateX(-50%);
} */


.investment-portfolio-section .invest-portfolio {
    width: auto;
    max-width: 1200px;
    margin: 50px auto;
    padding: 50px 15px 15px; 
    border: 1px solid #ffffff;
    /* background: url('../images/portfolio-border.svg') no-repeat;
    background-size: auto;
    position: relative; */

}

.investment-portfolio-section .ip-header {
    width: 80%;
    margin: auto;
    height: auto;
    position: relative;
    background: #1D273B;
    top: -70px;
 }

 .investment-portfolio-section .ip-header h5 {
    color: #FFF;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    position: relative;
    display: inline-block;
 }

 .investment-portfolio-section .ip-header p {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 28px */
 }

 .investment-portfolio-section .ip-header h5:after {
    content: "";
    display: block;
    width: 90%;
    margin: auto;
    height: 2px;
    bottom: 0px;
    z-index: 1;
    background: #D2433D;
}

.investment-portfolio-section .invest-portfolio .ip-masic-box {
    display: flex;
    height: 160px;
    margin: -20px auto 30px;
    justify-content: center;
    gap: 20px;
    align-items: center;
    border-radius: 10px;
    background-blend-mode: multiply;
    backdrop-filter: blur(10px);
    background: url('../images/masic-bg.webp') no-repeat;
    background-position: top center;
    background-size: cover;
}


.investment-portfolio-section.ar .invest-portfolio .ip-masic-box {
   
    display: flex;
    height: 160px;
    margin: -20px auto 30px;
    justify-content: center;
    gap: 20px;
    align-items: center;
    border-radius: 10px;
    background-blend-mode: multiply;
    backdrop-filter: blur(10px);
    background: url("../images/masic-bg-ar.webp") no-repeat;
    background-position: top center;
    background-size: cover;
}

.investment-portfolio-section .invest-portfolio .ip-masic-box img {
    height: 75%;    
}

.investment-portfolio-section .invest-portfolio .ip-masic-box p {
    color: #1D273B;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; 
    margin-bottom: 0px;
}

.investment-portfolio-section .invest-portfolio .invest-portfolio-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    width: auto;
    text-align: center;
    gap: 15px;
}

.investment-portfolio-section.ar .invest-portfolio .invest-portfolio-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    width: auto;
    text-align: center;
    gap: 15px;
    direction: ltr;
}

.investment-portfolio-section .invest-portfolio .portfolio-box {
    width: 100%;
    /* max-width: 392px; */
    justify-self: center;
    position: relative;
    border: 1px solid rgba(133, 133, 133, 0.60);
    border-radius: 18px;
    cursor: pointer;
    overflow: hidden;
}

.investment-portfolio-section .invest-portfolio .portfolio-box img {
    width: 100%;
    border-radius: 18px;
    transition: all 0.5s;
}

.investment-portfolio-section .invest-portfolio .portfolio-box .portfolio-des {
    position: absolute;
    width: 100%;
    top: 70%;
    transform: translateY(-50%);


}
.investment-portfolio-section .invest-portfolio .portfolio-box .portfolio-des .prot-logo-box {
    max-height: 70px;
    max-width: 170px;
    margin: auto auto 5px auto;
    border-radius: 4px;
    border: 1px solid rgba(133, 133, 133, 0.60);
    background: rgba(23, 34, 57, 0.60);
}

.investment-portfolio-section .invest-portfolio .portfolio-box:hover img.bg-img {
/* .investment-portfolio-section .invest-portfolio .portfolio-box:has(.prot-logo-box:hover) { */
    transform: scale(1.2);
}

.investment-portfolio-section .invest-portfolio .portfolio-box .portfolio-des .prot-logo-box img {
   max-height: 61px;
   width: auto;
   border-radius: 0px;
}

.investment-portfolio-section .invest-portfolio .portfolio-box h5 {
    color: #D1D2DF;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
}

.investment-portfolio-section .invest-portfolio .portfolio-box p {
    color: #D1D2DF;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.investment-portfolio-section .invest-portfolio .public-equity .portfolio-box .portfolio-des {
    top: 80%;
}

.investment-portfolio-section .invest-portfolio .public-equity .portfolio-box p {
    font-size: 18px;
}

.investment-portfolio-section .invest-portfolio .invest-portfolio-grid.real-estate-found {
    grid-template-columns: auto auto auto;
}

.investment-portfolio-section .invest-portfolio .invest-portfolio-grid.venture-capital {
    display: flex;
    justify-content: center;
    /* grid-template-columns: 33% 33% 33%; */
}

.investment-portfolio-section .invest-portfolio .invest-portfolio-grid.venture-capital .portfolio-box {
    width: 33%;
}

.investment-portfolio-section .invest-portfolio .invest-portfolio-grid.private-equity {
    grid-template-columns: auto auto auto auto;
}

.investment-portfolio-section .invest-portfolio .invest-portfolio-grid.private-equity .portfolio-box .tr-logo {
    position: absolute;
    width: 38px;
    height: 38px;
    top: 10px;
    right: 10px;
    border-radius: 0px;
}

.investment-portfolio-section .invest-portfolio .invest-portfolio-grid.private-equity .portfolio-box .tr-logo img {
    border-radius: 0px;
}

.investment-portfolio-section .invest-portfolio .invest-portfolio-grid.private-quity .tr-logo img {
    width: 100%;
    height: 100%;
}

.investment-portfolio-section .invest-portfolio .invest-portfolio-grid.public-quity {
    grid-template-columns: auto;
}

.investment-portfolio-section .invest-portfolio-grid.public-quity .portfolio-box .portfolio-des {
    top: unset;
    bottom: 0;
}



/* @media screen and (max-width: 600px) {
    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid {
        grid-template-columns: auto auto;
    }
} */




@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {
    .investment-port-section {
        padding: 50px 25px 0px;
        min-height: 350px;
        width: 90%;
        max-width: 540px;
    }

    .investment-port-section .text-box {
        padding: 25px 15px 15px;
        top: 30px;
    }

    .investment-port-section .text-box .heading-img {
        text-align: left;
    }

    .investment-port-section .text-box .heading-img img  {
        width: 75%;
    }

    .investment-port-section .text-box .heading-img h2  {
        font-size: 24px;
        font-weight: 400;
        line-height: 130%;
        position: absolute;
        width: 171px;
        top: 30px;
        left: 53%;
    }
    
    .investment-port-section .text-box p {
        color: #FFF;
        text-align: justify;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        margin-top: 25px;
    }

    
    .investment-port-section  .col-md-5,
    .investment-port-section  .col-md-6 {
        width: 100%;
    }


    /* investment-portfolio-section */

    .investment-portfolio-section {
        padding-bottom: 0px;
        padding-top: 250px;
    }
    .investment-portfolio-section .text-box {
        margin-top: 0;
    }
    
    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid {
        grid-template-columns: auto auto;
    }
    .investment-portfolio-section .portleft1 {
        position: absolute;
        top:350px;
        left: 0;
    }

    .investment-portfolio-section .portleft2 {
        position: absolute;
        top: unset;
        bottom: 0;
        left: 0;
    }

    .investment-portfolio-section .header h2 {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
    }

    .investment-portfolio-section .header p {
        font-size: 16px;
        line-height: 175%;
    }
    

    .investment-portfolio-section .ip-header h5 {
        font-size: 20px;
        font-weight: 400;
    }

    .investment-portfolio-section .ip-header p {
        font-size: 16px;
    }

    
    .investment-portfolio-section .invest-portfolio .ip-masic-box {
        background: url('../images/masic-bg-mb.webp') no-repeat;
        background-size: cover;
        height: 85px;
        justify-content: center;
        gap: 10;
        width: 100%;
        padding: 0px 10px;
    }

    .investment-portfolio-section .invest-portfolio .ip-masic-box img {
        height: 100%;
        padding: 10px;
    }
    .investment-portfolio-section .invest-portfolio .ip-masic-box p {
        font-size: 14px;
        text-align: left;
    }

    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.venture-capital .portfolio-box {
        width: 50%;
    }

    .investment-portfolio-section .invest-portfolio .portfolio-box,
    .investment-portfolio-section .invest-portfolio .portfolio-box img {
        border-radius: 5px;
    }
    
    .investment-portfolio-section .invest-portfolio .portfolio-box h5 {
        font-size: 12px;
        line-height: 100%;
        margin: 10px auto 0px;
    }

    .investment-portfolio-section .invest-portfolio .portfolio-box p {
        font-size: 10px;
    }

    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.public-equity {
        gap: 0px;
    }
    .investment-portfolio-section .invest-portfolio .public-equity .portfolio-box .portfolio-des {
        top: 70%;
    }
    
    .investment-portfolio-section .invest-portfolio .public-equity .portfolio-box p {
        font-size: 10px;
    }

    .investment-portfolio-section .invest-portfolio .portfolio-box .portfolio-des .prot-logo-box {
        max-width: 90px;
        height: 35px;
    }          

    .investment-portfolio-section .invest-portfolio .portfolio-box .portfolio-des .prot-logo-box img {
        max-height: 34px;
        width: auto;
    }

    /* private equity */
    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.private-equity {
     grid-template-columns: auto auto;
    }
    
    /* .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.private-equity .portfolio-box:nth-child(3) {
        grid-column-start: span 2;
    } */

    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.private-equity .portfolio-box .tr-logo {
        width: 20px;
        height: 20px;
        top: 5px;
    }

    /* public equity */
    .investment-portfolio-section .invest-portfolio.pb-low {
        padding: 20px 15px 15px;
    }

    .investment-portfolio-section .invest-portfolio.pb-low .ip-header {
        top: -40px;
    }

    .investment-portfolio-section .invest-portfolio-grid.public-quity .portfolio-box .portfolio-des {
        top: 80%;
        bottom: unset;
    }

    .investment-portfolio-section .invest-portfolio-grid.public-quity .portfolio-box .portfolio-des p {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    /* real-estate-found */
    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.real-estate-found {
        grid-template-columns: auto auto;
    }
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 350px)  {

    .investment-port-section .text-box .heading-img h2 {
        font-size: 18px;
        font-weight: 400;
        line-height: 130%;
        position: absolute;
        width: 130px;
        top: 23px;
        left: 54%;
    }

    .investment-portfolio-section .invest-portfolio .portfolio-box h5 {
        font-size: 10px;
    }

    .investment-portfolio-section .invest-portfolio .portfolio-box p {
        font-size: 8px;
    }

    .investment-portfolio-section .invest-portfolio .public-equity .portfolio-box p {
        font-size: 8px;
    }

}


@media only screen   and (min-device-width : 351px)  and (max-device-width : 420px)  {
    .investment-port-section .text-box .heading-img h2 {
        font-size: 20px;
        font-weight: 400;
        line-height: 130%;
        position: absolute;
        width: 145px;
        top: 24px;
        left: 53%;
    }

}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {
    .investment-port-section {
        padding: 50px 25px 0px;
        min-height: 450px;
        width: 90%;
        max-width: 720px;
    }

    .investment-port-section .desktop {
        display: none;
    }

    .investment-port-section .mobile {
        display: block;
    }

    .investment-port-section .text-box {
        padding: 25px 15px 15px;
        top: 50px;
    }

    .investment-port-section .text-box .heading-img {
        text-align: left;
    }

    .investment-port-section .text-box .heading-img img  {
        width: 75%;
    }

    .investment-port-section .text-box .heading-img h2  {
        font-size: 36px;
        font-weight: 400;
        line-height: 130%;
        position: absolute;
        width: 260px;
        top: 100px;
        left: 60%;
    }

    .investment-port-section.ar .text-box .heading-img h2  {
        right: 60%;
    }

    
    .investment-port-section .text-box p {
        color: #FFF;
        text-align: justify;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        padding-top: 20px;
    }

    .investment-port-section  .col-md-5,
    .investment-port-section  .col-md-6 {
        width: 100%;
    }


    /* investment-portfolio-section */

    .investment-portfolio-section {
        padding-bottom: 0px;
        padding-top: 200px;
    }

    .investment-portfolio-section .desktop {
        display: none;
    }

    .investment-portfolio-section .mobile {
        display: block;
    }

    .investment-portfolio-section .portleft1 {
        position: absolute;
        top:400px;
        left: 0;
    }

    .investment-portfolio-section .portleft2 {
        position: absolute;
        top: unset;
        bottom: 0;
        left: 0;
    }

    .investment-portfolio-section .header h2 {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: normal;
    }

    .investment-portfolio-section .header p {
        font-size: 16px;
        line-height: 175%;
    }

    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.public-equity {
        gap: 0px;
    }

    .investment-portfolio-section .ip-header h5 {
        font-size: 20px;
        font-weight: 400;
    }

    .investment-portfolio-section .ip-header p {
        font-size: 16px;
    }

    .investment-portfolio-section .invest-portfolio .ip-masic-box {
        background: url("../images/masic-bg.webp") no-repeat;
        background-size: cover;
        height: 125px;
        justify-content: center;
        gap: 20px;
        width: 100%;
        /* max-width: 480px; */
    }

    .investment-portfolio-section .invest-portfolio .ip-masic-box img {
        height: 100%;
        padding: 10px;
    }
    .investment-portfolio-section .invest-portfolio .ip-masic-box p {
        font-size: 14px;
    }

    .investment-portfolio-section .invest-portfolio .portfolio-box,
    .investment-portfolio-section .invest-portfolio .portfolio-box img {
        border-radius: 5px;
    }
    
    .investment-portfolio-section .invest-portfolio .portfolio-box h5 {
        font-size: 12px;
        line-height: 100%;
        margin: 10px auto 5px;
    }

    .investment-portfolio-section .invest-portfolio .portfolio-box p {
        font-size: 10px;
    }


    .investment-portfolio-section .invest-portfolio .portfolio-box .portfolio-des .prot-logo-box {
        max-width: 90px;
        height: 35px;
    }          

    .investment-portfolio-section .invest-portfolio .portfolio-box .portfolio-des .prot-logo-box img {
        max-height: 34px;
        width: auto;
    }

    /* private equity */
    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.private-equity {
     grid-template-columns: auto auto auto auto;
    }
    
    /* .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.private-equity .portfolio-box:nth-child(3) {
        grid-column-start: span 2;
    } */

    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.private-equity .portfolio-box .tr-logo {
        width: 20px;
        height: 20px;
        top: 5px;
    }

    /* public equity */
    .investment-portfolio-section .invest-portfolio.pb-low {
        padding: 20px 15px 15px;
    }

    .investment-portfolio-section .invest-portfolio.pb-low .ip-header {
        top: -40px;
    }

    .investment-portfolio-section .invest-portfolio-grid.public-quity .portfolio-box .portfolio-des {
        top: 85%;
        bottom: unset;
    }

    .investment-portfolio-section .invest-portfolio-grid.public-quity .portfolio-box .portfolio-des p {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    /* real-estate-found */
    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.real-estate-found {
        grid-template-columns: auto auto;
    }

    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.venture-capital {
        display: flex;
        justify-content: center;
    }

    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.venture-capital .portfolio-box {
        width: 50%;
    }

    .investment-portfolio-section .invest-portfolio .invest-portfolio-grid.venture-capital .portfolio-box .prot-logo-box img {
        max-width: 70px;
        max-height: unset;
    }
    

}



@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
    .investment-port-section {
       padding-top: 450px;
    }

    .investment-portfolio-section {
        padding-bottom: 50px;
    }
    
    .investment-port-section .text-box {
        max-width: 950px;
    }

    .investment-section .text-box {
        top: 50px;
    }

}





@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  {
 
    .investment-portfolio-section .portleft1 img ,
    .investment-portfolio-section .portleft2 img,
    .investment-portfolio-section .portright1 img, 
    .investment-portfolio-section .portright2 img {
        width: 50%;
    }

    .investment-portfolio-section .portleft1 ,
    .investment-portfolio-section .portleft2 {
        text-align: left;
    }

    .investment-portfolio-section .portleft2 {
        top:65%
    }

    .investment-portfolio-section .portright1 , 
    .investment-portfolio-section .portright2 {
        text-align: right;
    }

}

@media only screen   and (min-device-width : 1281px)  and (max-device-width : 1360px)  {
    .investment-portfolio-section .portleft1 img ,
    .investment-portfolio-section .portleft2 img,
    .investment-portfolio-section .portright1 img, 
    .investment-portfolio-section .portright2 img {
        width: 60%;
    }

    .investment-portfolio-section .portleft1 ,
    .investment-portfolio-section .portleft2 {
        text-align: left;
    }

    .investment-portfolio-section .portright1 , 
    .investment-portfolio-section .portright2 {
        text-align: right;
    }
}


@media only screen   and (min-device-width : 1361px)  and (max-device-width : 1600px)  {
    .investment-portfolio-section .portleft1 img ,
    .investment-portfolio-section .portleft2 img,
    .investment-portfolio-section .portright1 img, 
    .investment-portfolio-section .portright2 img {
        width: 70%;
    }

    .investment-portfolio-section .portleft1 ,
    .investment-portfolio-section .portleft2 {
        text-align: left;
    }

    .investment-portfolio-section .portright1 , 
    .investment-portfolio-section .portright2 {
        text-align: right;
    }
}