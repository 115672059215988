.contactbody-section{
    background: #1D273B;
    position: relative;
    overflow: hidden;
}

.contactbody-section.ar {
    direction: rtl;
}

.contactbody-section .form-container form{
    direction: ltr;
}
.contactbody-section .form-container{
    background: #EDF1F5;
    text-align: left;
    padding-top: 60px;
    padding-bottom: 50px;
  
}
.contactbody-section .contactform-para{
    color: #1D273B;
    font-family: Vazirmatn;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.contactbody-section .contactform-heading{
    color: #0F001A;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 30px;
    padding-bottom: 20px;
}
.contactbody-section .padding-right{
    padding-right: 20%;
}

.contactbody-section .padding-left{
    padding-left: 20%;
}

.contactbody-section .btn-wrap {
    margin:auto;
    text-align: center;
    margin-top: 1rem;
}

.contactbody-section .submit-btn{
    border: 1px solid #A12222;
    background: #D2433D;
    color: #FFF;
    text-align: center;
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    width: 84%;
    height: 64px;
    width: 100%;
/* margin-left: 7px; */
}
/* address div */
.contactbody-section .address-div{
    max-width: 476px;
    width: 100%;
    height: auto;
    padding: 50px 0px 50px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.contactbody-section.ar .address-div{
    padding: 50px 100px 50px 0px;
    text-align: right;
}

.contactbody-section.ar .address-div svg{
    margin-left: 5px;
}

.contactbody-section .form-container .contact-form {
    max-width: 476px;
    width: 100%;
    height: auto;
    margin: auto 5% auto auto;
    position: relative;
}

.contactbody-section.ar .form-container .contact-form {
    max-width: 456px;
    margin: auto auto 5% auto;
    text-align: right;
}

.contactbody-section.ar .form-container .contact-form .PhoneInputCountrySelectArrow {
    margin-right: 10px;
}


.contactbody-section .address-div p{
    color: #FFF;
    font-family: Vazirmatn;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* margin-left: 10px;
    padding-left: 10px; */
}
.contactbody-section iframe{
    border-radius: 18px;

}
.contactbody-section svg{
    margin-right: 4px;
    fill:  #FFF;
}

.contactbody-section .contact-form svg {
    fill: #000000;
}

.contactbody-section .address-paragraph {
padding-left: 15px;
}
.contactbody-section .address-paragraph2{
padding-left: 13px;
}

.contactbody-section .address-paragraph a,
.contactbody-section .address-paragraph2 a {
    color: #ffffff;
}

.contactbody-section .leftpadding{
padding-left: 38px;
}
.contactbody-section .leftpadding1
{
    padding-left: 33px;

}
/* form */
/* TextField{

} */
/* .contactbody-section label{
    color: #0F001A;
    font-family: Vazirmatn;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
} */
textarea.w-100.form-control {
    margin-left: 7px;
    margin-top: 10px;
}
/* .contactbody-section .form-control{
    width: 80% !important;
} */



.contactbody-section .errormsg{
color: red;
font-weight:600;
margin-left: 7px;

}
.contactbody-section  .form-control,.msgfield{
    background: #EDF1F5;
    border-radius: 4px;
    border: 1.2px solid #B4BEC8;
}


.contactbody-section .leftbottom {
    position: absolute;
    left: 50px;
    bottom: 0px;
}

.contactbody-section .righttop {
    position: absolute;
    right: 0px;
    top: 50px;
}

.contactbody-section.ar .leftbottom {
    left: unset;
    right: 50px;
    bottom: 0px;
}

.contactbody-section.ar .righttop {
    right: unset;
    left: 0px;
    top: 50px;
}

.contactbody-section.ar .righttop img,
.contactbody-section.ar .leftbottom img {
    transform: scaleX(-1);
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 350px)  { 

    .contactbody-section .address-div p {
        font-size: 11px;
    }

}

@media only screen   and (min-device-width : 351px)  and (max-device-width : 410px)  { 

    .contactbody-section .address-div p {
        font-size: 13px;
    }

}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 

    .contactbody-section .righttop {
        top: 20px;
    }

    .contactbody-section .col-md-6 {
        width: 100%;
        max-width: 540px;
        margin: auto;
        text-align: center;
    }

    .contactbody-section .leftbottom {
        left: unset;
        right: 0;
        z-index: 1;
    }

    .contactbody-section .form-container {
        padding-top: 60px;
        padding-bottom: 50px;
        order: 1;
    }

    /* .contactbody-section .address-div {
        overflow-x: hidden;
    } */
 
    .contactbody-section .padding-right {
        padding-right: 0px;
    }
    .contactbody-section .padding-left{
        padding-left: 0%;
    }

    .contactbody-section .framemap {
        text-align: center;
    }

    .contactbody-section iframe {
        max-width: 100%;
        text-align: center;
    }

    .contactbody-section .address-div,
    .contactbody-section.ar .address-div {
        padding: 50px 20px;
        margin: auto;
    }
    
    .contactbody-section .btn-wrap {
        margin-right: 0px;
    }

    .contactbody-section .contactform-para {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; 
        text-align: center;
        margin-bottom: 0px;
    }

    .contactbody-section .contactform-heading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0px;
        text-align: center;
    }

    .contactbody-section .submit-btn {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 222.222%; 
        width: 244px;
    }

}

@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 

    .contactbody-section .desktop {
        display: none;
    }

    .contactbody-section .mobile {
        display: block;
    }

    .contactbody-section .righttop {
        top: 20px;
    }

    .contactbody-section .col-md-6 {
        width: 100%;
        max-width: 540px;
        margin: auto;
        text-align: center;
    }

    .contactbody-section .leftbottom {
        left: unset;
        right: 0;
        z-index: 1;
    }

    .contactbody-section .form-container {
        padding-top: 60px;
        padding-bottom: 50px;
        order: 1;
    }

    .contactbody-section .padding-right {
        padding-right: 0px;
    }

    .contactbody-section .padding-left{
        padding-left: 0%;
    }

    .contactbody-section .framemap {
        text-align: center;
    }

    .contactbody-section iframe {
        max-width: 100%;
        text-align: center;
    }

    .contactbody-section .address-div {
        padding: 50px 20px;
        margin: auto;
    }
    
    .contactbody-section .btn-wrap {
        margin-right: 0px;
    }

    .contactbody-section .contactform-para {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; 
        text-align: center;
        margin-bottom: 0px;
    }

    .contactbody-section .contactform-heading {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0px;
        text-align: center;
    }

    .contactbody-section .submit-btn {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 222.222%; 
        width: 244px;
    }

}


@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
   
    .contactbody-section .leftbottom img, 
    .contactbody-section .righttop img {
        width: 50%;
    }

    .contactbody-section .leftbottom {
        text-align: left;
    }
    
    .contactbody-section .righttop {
        text-align: right;
    }

    .contactbody-section.ar .righttop {
        text-align: left;
    }

    .contactbody-section.ar .leftbottom {
        text-align: right;
    }
    
}



@media only screen   and (min-device-width : 1281px)  and (max-device-width : 1360px)  {
   
    .contactbody-section .leftbottom img, 
    .contactbody-section .righttop img {
        width: 60%;
    }

    .contactbody-section .leftbottom {
        text-align: left;
    }
    
    .contactbody-section .righttop {
        text-align: right;
    }

    
    .contactbody-section.ar .righttop {
        text-align: left;
    }

    .contactbody-section.ar .leftbottom {
        text-align: right;
    }
}


@media only screen   and (min-device-width : 1361px)  and (max-device-width : 1600px)  {
   
    .contactbody-section .leftbottom img, 
    .contactbody-section .righttop img {
        width: 70%;
    }

    .contactbody-section .leftbottom {
        text-align: left;
    }
    
    .contactbody-section .righttop {
        text-align: right;
    }

    
    .contactbody-section.ar .righttop {
        text-align: left;
    }

    .contactbody-section.ar .leftbottom {
        text-align: right;
    }
}