.ppolicybody-section {
    display: flex;
    /* width: 1792px; */
    padding: 61px 205px 109px 205px;
    flex-direction: column;
    align-items: center;
    background: #EDF1F5;
}

.ppolicybody-section.ar {
  direction: rtl;
}

.ppolicybody-section p{
    color: #1D273B;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ppolicybody-section  .tabdiv{
    overflow-y: scroll;
    height: 100%;
    max-height: 80vh;
    scrollbar-width: none !important;
    -ms-overflow-style: none;

  }
  ::-webkit-scrollbar {
    display: none;
}
.ppolicybody-section h6{
  font-weight: 900;
  font-family: Vazirmatn;
  color: #1D273B;
  font-size: 20px;
  line-height: normal;
 
  /* font-size: 30px; */

}

.ppolicybody-section svg{
margin-right: 10px;
}
.ppolicybody-section img{
  margin-right: 10px;
}

.ppolicybody-section.ar svg{
  margin-left: 10px;
}
.ppolicybody-section.ar img{
  margin-left: 10px;
}

  
.ppolicybody-section h3{
  color: #1D273B;
  text-align: center;
  font-family: Vazirmatn;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transform: translateX(-20px);
  /* text-align: left !important; */
}

.ppolicybody-section.ar h3 {
  text-align: right;
}

/* Style the tab */
.ppolicybody-section .tab {
    float: left;
    /* border: 1px solid #ccc; */
    width: 100%;
    height: 300px;
  }
  
  /* Style the buttons inside the tab */
  .ppolicybody-section .tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 14px 16px;
    /* margin-right: 0px; */
    width: 90%;
    display: flex;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
    border-radius: 26.5px;
    margin-bottom: 15px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    background: #FFF;
  }
  
  /* Change background color of buttons on hover */
  .ppolicybody-section .tab button:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current "tab button" class */
  .ppolicybody-section .tab button.active {
    background-color: #ccc;
  }
  
  /* Style the tab content */
  .ppolicybody-section .tabcontent {
    display: none;
    text-align: left;
    padding: 0px 12px;
    width: 100%;
    border-left: none;
    height: 100%;
  }

  .ppolicybody-section.ar .tabcontent {
    text-align: right;
  }

  .ppolicybody-section .tabcontent.active {
    display: block;
  } 


  .ppolicybody-section .tabcontent p {
    color: #1D273B;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


@media only screen and (min-device-width : 320px)  and (max-device-width : 1023px)  { 
  .ppolicybody-section {
    padding: 50px 20px;
  }

  .ppolicybody-section h3 {
    display: none
  }


  .ppolicybody-section .col.col-md-3,
  .ppolicybody-section .col.col-md-9 {
    max-width: 540px !important;
    width: 100% !important;
  }

  .ppolicybody-section p,   
  .ppolicybody-section h6 {
    font-size: 16px !important;
  }

  /* .ppolicybody-section .col-md-3 {
    max-width: 20% !important;
  }

  .ppolicybody-section .tab {
    height: auto;
  }

  .ppolicybody-section .tab button {
    width: 100%;
  } 

  .ppolicybody-section .tab button span {
    display: inline-block;
  }

  .ppolicybody-section .tab button span:nth-child(2) {
    display: none;
  } 

  .ppolicybody-section .tab button:hover span:nth-child(2) {
    display: block;
  } 
*/
  .ppolicybody-section .tabdiv {
    max-height: 100%;
    margin-top: 100px;
  }
}


@media only screen and (min-device-width : 768px)  and (max-device-width : 1023px)  {  
  .ppolicybody-section .col-md-3,
  .ppolicybody-section .col-md-9 {
    max-width: 720px !important;
    width: 100% !important;
  }

  .ppolicybody-section .tabdiv {
    max-height: 100%;
    margin-top: 80px;
  }

}

@media only screen and (min-device-width : 1024px)  and (max-device-width : 1100px)  {  
  .tab button {
    width: 110%;
  }
}

@media only screen and (min-device-width : 1024px)  and (max-device-width : 1280px)  {  

.ppolicybody-section .tab button img {
  max-width: 16px;
}
.ppolicybody-section .tab button {
  font-size: 15px;
}

.ppolicybody-section .col.col-md-3 {
  max-width: 30% !important;
  width: 100% !important;
}

.ppolicybody-section .col.col-md-9 {
  max-width: 70% !important;
  width: 100% !important;
}

}