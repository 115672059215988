.corevalue-section{
    background: #EDF1F5;
    position: relative;
    overflow-x: hidden;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.corevalue-section.ar {
    direction: rtl;
}

.corevalue-section .corevalue-heading{
    color: #000;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
}
.corevalue-section .corevalue-para{
    color: #000;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;  
}

/* .corevalue-sectionimg{
    width: 100%;
  
} */
.corevalue-section .bg-box{
    border-radius: 10px;
    background: #28364D;
    backdrop-filter: blur(5px);
    height: 456px;
    }

.corevalue-section .inner-div{
    padding: 20px;
}
.corevalue-section .img-div{
    height: 121px;
}

.corevalue-section .bg-box img{
    padding-top: 50px;
    width: 100%;
    max-width: 110px;
    /* height: 90.099px; */
    flex-shrink: 0;
    /* padding-bottom: 20px; */
}
.corevalue-section .img-heading{
    font-family: 'Vazirmatn';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 128%;
    text-align: center;
    padding-top: 20px !important;
    color: #FFFFFF;
}
.corevalue-section .img-para{
    font-family: 'Vazirmatn';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: justify;
    color: #C8C8C8;
    padding-top: 0.5rem !important;
}

/* .corevalue-section.dflex{
display: flex !important;
} */


.corevalue-section .leftbottom {
    position: absolute;
    left: 0px;
    bottom: 50px;

}

.corevalue-section .righttop {
    position: absolute;
    right: 0px;
    top: 0px;
}


@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {
    .corevalue-section {
        padding-top: 0px;
        padding-bottom: 0px; 
    }

    .corevalue-section .leftbottom {
        bottom: 0px;
    }
    
    .corevalue-section .heading-box {
        max-width: 294px;
        margin: auto;
    }

    .corevalue-section .corevalue-heading{
        color: #000;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }

    .corevalue-section .corevalue-para{
        color: #000;
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        line-height: 130%; 
    }

    .corevalue-section .bg-box {
        max-width: 300px;
        height: auto;
        margin: 15px auto;
    }
    
    .corevalue-section .img-div {
        height: 100px;
    }

    .corevalue-section .bg-box img{
        padding-top: 20px !important;
        width: 100%;
        max-width: 80px;
        flex-shrink: 0;
    }

    .corevalue-section .img-heading{
        color: #EDF1F5;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 128%; 
        padding-top: 0px !important;
        margin-top: 10px !important;
    }

    .corevalue-section .img-para{
        color: #C8C8C8;
        text-align: justify;
        font-size: 14px;
        line-height: 150%; 
        margin-top: 0px !important;
    }
    
}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 
    .corevalue-section .col-md-3 {
        width: 50%;
    }

    .corevalue-section .desktop {
        display: none;
    }

    .corevalue-section .mobile {
        display: block;
    }

    .corevalue-section .heading-box {
        max-width: 494px;
        margin: auto;
    }
    
    .corevalue-section .corevalue-heading{
        color: #000;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }

    .corevalue-section .corevalue-para{
        color: #000;
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        line-height: 130%; 
    }

    .corevalue-section .bg-box {
        max-width: 300px;
        height: 350px;
        margin: 15px auto;
    }
    
    .corevalue-section .img-div {
        height: 100px;
    }

    .corevalue-section .bg-box img{
        padding-top: 20px !important;
        width: 100%;
        max-width: 80px;
        flex-shrink: 0;
    }

    .corevalue-section .img-heading{
        color: #EDF1F5;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 128%; 
        padding-top: 0px !important;
        margin-top: 10px !important;
    }

    .corevalue-section .img-para{
        color: #C8C8C8;
        text-align: justify;
        font-size: 14px;
        line-height: 150%; 
        margin-top: 0px !important;
    }
}



@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
    

    .corevalue-section .col-md-10 {
        width: 100%;
    }

    /* .corevalue-section .bg-box {
        height: 500px;
    } */

    .corevalue-section .bg-box img ,
    .corevalue-section .img-heading {
        padding-top: 20px !important;
        margin-top: 0px !important;
    }

    .corevalue-section .img-para {
        padding-top: 10px !important;
        margin-top: 0px !important;
    }
}

@media only screen   and (min-device-width : 1281px)  and (max-device-width : 1360px)  { 
    /* .corevalue-section .bg-box {
        height: 500px;
    } */
}


@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1600px)  { 
    .corevalue-section .leftbottom img,
    .corevalue-section .righttop img { 
        width: 70%;
    }

    .corevalue-section .leftbottom {
        text-align: left;
    }
    
    .corevalue-section .righttop {
        text-align: right;
    }

}