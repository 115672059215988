.contactheader-section {
    background-image: url(../images/contact-header-bg.webp);
    background-repeat: no-repeat;
    background-size: cover !important;
    height: auto;
    min-height: 100vh;
    position: relative;
    overflow-x: hidden; 
}

.contactheader-section .contact-inner-div{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);

}
.contactheader-section .contact-heading {
    color: #FFF;
    text-align: center;
    font-size: 60px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
    text-transform: uppercase;
}

.contactheader-section .contact-paragraph {
    color: #FFF;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* .contactheader-section .paragraph-div {
    position: absolute;
    top: 50% !important;
    left: 18%;
} */
.contactheader-section  .learnmore-btn{
    background: #D2433D;
    border: 1px solid #A22222;
    color: #FFFFFF;
    width: 244px;
    height: 64px;
    font-family: Helvetica !important;
    position: absolute;
    top: 70%;
    left: 43%;
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 
    .contactheader-section {
        background-image: url(../images/contact-header-bg-mb.webp);
    }

    .contactheader-section .contact-inner-div {
        top: 30%;
        max-width: 400px;
        width: 90%;
    }

    .contactheader-section .contact-heading {
        font-size: 36px;
        line-height: 130%; 
    }
    
    .contactheader-section .contact-paragraph {
        font-size: 16px;
        line-height: normal;
    }
    
}

@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 
    .contactheader-section {
        background-image: url(../images/contact-header-bg-mb.webp);
    }

    .contactheader-section .contact-inner-div {
        top: 40%;
    }
    
    .contactheader-section .contact-heading {
        font-size: 36px;
        line-height: 130%; 
    }
    
    .contactheader-section .contact-paragraph {
        font-size: 16px;
        line-height: normal;
    }
    
}