.explore-section {
    background: #FFF;
    position: relative;
    overflow-y: hidden;
}

.explore-section.ar {
    direction: rtl;
}

.explore-section .explore-image {
    padding-top: 150px;
    padding-bottom: 150px;
    width: 100%;
}

.explore-section.ar .explore-image  {
    transform: scaleX(-1);
}

.explore-section .explore-heading {
    color: #1D273B;
    font-size: 60px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
}

.explore-section .contact-btn {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    border: 1px solid #A12222;
    background: #D2433D;
    width: 244px;
    height: 64px;
}

.explore-container {
    position: absolute;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
}

.explore-section.ar .explore-container {
    left: unset;
    right: 0;
} 

.explore-section .bottomright {
    position: absolute;
    bottom: 0px;
    right: 20px;
}

.explore-section .topleft {
    position: absolute;
    top: 0px;
    left: 0px;
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {
    .explore-section {
        background: #edf1f5;
        overflow: hidden;
    }

    .explore-section .topleft {
        top: 50px;
    }

    .explore-section .img-text {
        padding-top: 20px;
        width: 100%;
        text-align: center;
        margin: auto;
    }

    .explore-section .explore-heading {
        color: #1D273B;
        text-align: center;
        font-size: 36px;
        font-weight: 300;
        line-height: 130%;
        max-width: 400px;
        margin: auto;
    }

    .explore-section .img-text .explore-image {
        padding-top: 10px;
        padding-bottom: 0px;
        width: 100vw;
    }

    .explore-section .contact-btn {
        margin: 20px auto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 250%;
        width: 180px;
        height: 48px;
    }

}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {
    .explore-section {
        background: #edf1f5;
        overflow: hidden;
    }

    .explore-section .desktop {
        display: none;
    }

    .explore-section .mobile {
        display: block;
    }
    
    .explore-section .topleft {
        top: 50px;
    }

    .explore-section .img-text {
        padding-top: 20px;
        width: 100%;
        max-width: 720px;
        text-align: center;
        margin: auto;
    }

    .explore-section .explore-heading {
        color: #1D273B;
        text-align: center;
        font-size: 36px;
        font-weight: 300;
        line-height: 130%;
        max-width: 400px;
        margin: auto;
    }

    .explore-section .img-text .explore-image {
        padding-top: 10px;
        padding-bottom: 0px;
        width: 100%;
    }

    .explore-section .contact-btn {
        margin: 20px auto;
        margin: 20px auto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 250%;
        width: 180px;
        height: 48px;
    }

}



@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1600px)  { 
    .explore-section .contact-btn {
        width: 220px;
        height: 60px;
        font-size: 20px;
    }
}


@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 

    
    .explore-section .bottomright img,
    .explore-section .topleft img {
        width: 50%;
    }

    .explore-section .bottomright {
        text-align: right;
    }
    
    .explore-section .topleft {
        text-align: left;
    }

    .explore-section .img-text {
        width: 85%;
    }

    .explore-section .explore-image {
        padding-top: 120px;
        padding-bottom: 80px;    
    }

    .explore-section .img-text .img-text-heading {
        line-height: 120%;
    }
}



@media only screen   and (min-device-width : 1281px)  and (max-device-width : 1360px)  {
    .explore-section .bottomright img,
    .explore-section .topleft img {
        width: 60%;
    }

    .explore-section .bottomright {
        text-align: right;
    }
    
    .explore-section .topleft {
        text-align: left;
    }
}


@media only screen   and (min-device-width : 1361px)  and (max-device-width : 1600px)  {
    .explore-section .bottomright img,
    .explore-section .topleft img {
        width: 70%;
    }

    .explore-section .bottomright {
        text-align: right;
    }
    
    .explore-section .topleft {
        text-align: left;
    }
}