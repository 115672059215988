.termsandconditon-section {
    display: flex;
    /* width: 1792px; */
    padding: 61px 205px 109px 205px;
    flex-direction: column;
    align-items: center;
    background: #EDF1F5;

}

.termsandconditon-section.ar {
    direction: rtl;
}

.termsandconditon-section .termsandconditon-para {
    color: #1D273B;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.termsandconditon-section.ar .termsandconditon-para {
    text-align: right;
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {
    .termsandconditon-section {
        padding: 50px 20px
    }

    .termsandconditon-section .termsandconditon-para {
        font-size: 16px;
    }
}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {
    .termsandconditon-section {
        padding: 50px 20px
    }

    .termsandconditon-section .termsandconditon-para {
        font-size: 16px;
    }
}
