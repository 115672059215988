.investment-bg {
    background: #EDF1F5;
    height: 100%;
    margin-top: 0px;
    position: relative;
}

.investment-section {
    min-height: 400px;
    width: 100%;
    background: #EDF1F5;
    padding: 100px;
    position: relative;
    overflow: hidden;
}

/* .investment-section .investment-img {
    width: 100%;
    margin-top: 170px;
    Height:701px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
} */
.investment-section .investment-bg {
    opacity: 0.800000011920929;
    /* background: linear-gradient(132deg, #2B3A52 0%, #2B3A52 100%); */
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    top: 10%;
}

.investment-section .img-text {
    color: #FFFFFF;
    width: 100%;
    margin: auto;
    max-width: 1200px;
    padding: 60px;
    z-index: 4;
    position: relative;
    border-radius: 20px;
    background: linear-gradient(180deg, #5E83AE 0%, #446FA0 52.08%, #507CAE 100%);
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.25);
    /* background: #5E83AE; */
}

.investment-section .topright {
    position: absolute;
    top: calc(-50% - 120px);
    text-align: right;
    right: 50px;
}

.investment-section .bottomleft {
    position: absolute;
    bottom: -100px;
    text-align: left;
    left: 0px;
}

/* .investment-section .bottomleft img,
.investment-section .topright img {
    width: 85%;
} */

.investment-section .img-heading {
    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 60px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    text-align: left;
}

.investment-section .img-heading.ar {
    text-align: right;
}


.investment-section .img-para {

    color: #FFF;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 40px;
    text-align: justify !important;
}

.investment-section button {
    box-sizing: border-box;

    /* position: absolute; */
    width: 244px;
    height: 64px;
    /* left: calc(50% - 244px/2 - 55px);
top: 1673px; */
    display: flex;
    align-items: center;
    text-align: center !important;
    justify-content: center !important;
    background: #D2433D;
    /* vertical-align: middle; */
    /* padding-top: 17px; */
    border: 1px solid #A22222;
    height: 64px;
    color: #FFF;
    /* text-align: center; */
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 166.667% */
}

.embracing-section {
    background-image: url(../images/embracingbgVision.webp);
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: bottom center;
    /* margin-top: 120px; */
    padding: 80px 0px 150px;
}

.embracing-section.ar {
    background-image: url(../images/embracingbgVision-ar.webp);
    padding-bottom: 15%;
}

.embracing-section h2 {
    color: #FFF;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-top: 60px;
    padding-top: 40px;
    color: #FFFFFF;
    text-align: left;
}

.embracing-section.ar h2 {
    text-align: right;
}

.embracing-section .boldheading {
    color: #FFF;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

/* .embracing-section .embrac-para1 {
    font-family: 'Vazirmatn';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 160%;
   
    text-align: left !important;

    color: #FFFFFF;
} */

.embracing-section .embrac-para2 {
    color: #FFF;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%;
    /* 32px */
}

.embracing-section .paragraph-div {
    margin-bottom: 10px;
    padding-bottom: 100px;
}

.embracing-section .bottom-border, .embracing-section .reltv-potn {
    position: relative;
}

.embracing-section .bottom-border::after {
    /* border-bottom: 4px solid #FFC100; */
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    flex-shrink: 0;
    left: 1%;
    background-color: #FFC100;
    top: 85%
}
.embracing-section .paddingleft{
    padding-left: 20px;
}
.embracing-section .vision-logo{
    position: absolute;
    top: 15%;
/* left: 50%; */
}

.investment-bg .message-section {
    /* height: 550px; */
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    background: #30394B;
    position: relative;
}

.investment-bg .message-section .msg-para{

    color: #FFF;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    z-index: 3;
}
.investment-bg .message-section .sub-para{
    color: #FFF;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: justify;
    position: relative;
    z-index: 3;
}
.investment-bg .message-section .msg-heading {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 225%;
    z-index: 2;

}

.message-section .msg-btn {
    background: #D2433D;
    border: 1px solid #A22222;
    color: #FFFFFF;
    width: 244px;
    height: 64px;
    font-family: Helvetica !important;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    margin-top: 30px;
    padding: 10px 60px 25px;
    position: relative;
    z-index: 3;
}
.message-section .paddingtop{
    padding-top: 120px;
    padding-bottom: 120px;
}
.message-section .leftmsgimg,.message-section .rightmsgimg{
    position: absolute;
    z-index: 0;
}
.message-section .leftmsgimg{
    top: 10%;
    left:3%;
    width: 359px;
/* height: 100%;
min-height: 30vh; */
    /* width: 359px; */
}
.message-section .rightmsgimg{
    top: 40%;
    right: 5%;
}





@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 
    .investment-section { 
        padding: 25px;
    }
    
    .investment-section .topright {
        top: 0px;
        right: 0px;
    }
    
    .investment-section .bottomleft {
        bottom: 0px;
        left: 0px;
    }
    .investment-section .img-heading {
        font-size: 36px;
        font-style: normal;
        text-align: left;
    }

    .investment-section .img-heading.ar {
        text-align: right;
    }
    
    .investment-section .img-text {
        padding: 20px;
    }

    .investment-section .img-para {
        font-size: 16px;
        line-height: normal;
        padding-top: 10px;
        padding-left: 30px;
    }

    .investment-section button {
        font-size: 16px;
        line-height: 40px;
        max-width: 180px;
        width: 100%;
        height: 48px;
        margin: auto;
    }


    /* embracing-section */

    .embracing-section,    .embracing-section.ar {
        background-image: url(../images/embracingbgVision-mb.webp);
        padding: 50px 0px 0px;
    }
 
    .embracing-section .col-md-6 {
        width: 100%;
        padding: 20px;
    }


    .embracing-section h2, .embracing-section h2 .boldheading { 
        margin-top: 0px;
        padding-top: 0px;
        color: #FFF;
        text-align: center;
        font-size: 24px;
        font-weight: 300;
    }

    .embracing-section .mobile .vision-logo {
        position: unset;
        width: 100%;
        margin: 20px auto;
    }

    .embracing-section .embrac-para2 {
        color: #FFF;
        text-align: justify;
        font-family: Vazirmatn;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 160%;
    }

    .embracing-section .paragraph-div {
        padding-bottom: 50px;
    }

    /* message-section */

    .investment-bg .message-section {
        margin-top: 0px;
        padding: 20px;
    }

    .relative {
        position: relative;
    }

    .investment-bg .message-section .msg-heading {
        font-size: 24px;
        line-height: normal;
        margin-bottom: 15px;
        z-index: 2;

    }

    .investment-bg .message-section .msg-para {
        text-align: justify;
        font-size: 16px;
        line-height: normal;
    }

    .investment-bg .message-section .sub-para {
        font-size: 16px;
        line-height: normal;
    }

    .message-section .paddingtop {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .message-section .leftmsgimg {
       max-width: 105px;
        top: -350x;
    }

    .message-section .rightmsgimg {
        max-width: 105px;
        top: unset;
        bottom: 80px;
    }

    .message-section .leftmsgimg img,
    .message-section .rightmsgimg img {
        width: 100%;
    }

    .message-section .msg-btn {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 250%;
        padding: 5px 50px;
        width: unset;
        height: 48px;
    }
}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 

    .investment-bg .mobile {
        display: block;
    }

    .investment-bg .desktop {
        display: none;
    }

    .investment-section .col-md-8 {
        width: 100%;
    }

    .investment-section { 
        padding: 25px;
    }
    
    .investment-section .topright {
        top: 0px;
        right: 0px;
    }
    
    .investment-section .bottomleft {
        bottom: 0px;
        left: 0px;
    }
    .investment-section .img-heading {
        font-size: 36px;
        font-style: normal;
        text-align: left;
    }

    .investment-section .img-text {
        padding: 20px;
        max-width: 760px;
    }

    .investment-section .img-para {
        font-size: 16px;
        line-height: normal;
        padding-top: 10px;
        padding-left: 30px;
    }

    .investment-section button {
        font-size: 16px;
        line-height: 40px;
        max-width: 180px;
        width: 100%;
        height: 48px;
        margin: auto;
    }


    /* embracing-section */

    .embracing-section,
    .embracing-section.ar {
        background-image: url(../images/embracingbgVision-mb.webp);
        padding: 50px 0px 0px;
    }
 
    .embracing-section .col-md-6 {
        width: 100%;
        max-width: 760px;
        margin: auto;
        padding: 20px;
    }


    .embracing-section h2, .embracing-section h2 .boldheading { 
        margin-top: 0px;
        padding-top: 0px;
        color: #FFF;
        text-align: center;
        font-size: 24px;
        font-weight: 300;
    }

    .embracing-section .mobile .vision-logo {
        position: unset;
        width: 100%;
        margin: 20px auto;
    }

    .embracing-section .embrac-para2 {
        color: #FFF;
        text-align: justify;
        font-family: Vazirmatn;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 160%;
    }

    .embracing-section .paragraph-div {
        padding-bottom: 50px;
    }

    /* message-section */

    .investment-bg .message-section {
        margin-top: 0px;
        padding: 20px;
    }

    .relative {
        position: relative;
    }

    .investment-bg .message-section .msg-heading {
        font-size: 24px;
        line-height: normal;
        z-index: 2;
    }

    .investment-bg .message-section .msg-para {
        text-align: justify;
        font-size: 16px;
        line-height: normal;
    }

    .investment-bg .message-section .sub-para {
        font-size: 16px;
        line-height: normal;
    }

    .message-section .paddingtop {
        padding-top: 80px;
        padding-bottom: 80px;
        max-width: 760px;
    }

    .message-section .leftmsgimg {
       max-width: 105px;
        top: -50px;
    }

    .message-section .rightmsgimg {
        max-width: 105px;
        top: unset;
        bottom: 80px;
    }

    .message-section .leftmsgimg img,
    .message-section .rightmsgimg img {
        width: 100%;
    }

    .message-section .msg-btn {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 250%;
        padding: 5px 50px;
        width: unset;
        height: 48px;
    }
}



@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1600px)  { 

    .investment-section .bottomleft img,
    .investment-section .topright img {
        width: 70%;
    }
    .investment-section .topright {
        text-align: right;
        top: calc(-50% - 0px);
        right: 50px;
    }
    
    .investment-section .bottomleft {
        text-align: left;
    }

    .investment-section button {
        width: 220px;
        height: 60px;
        font-size: 20px;
    }

}



/* 
@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1360px)  { 

    .investment-section .topright img {
        width: 52%;
    }

    .investment-section .bottomleft img {
        width: 45%;
    }
    
    .investment-section .topright {
        text-align: right;
        top: calc(-35% - 0px);
        right: 25px;
    }
    
    .investment-section .bottomleft {
        text-align: left;
    }

    .investment-section button {
        width: 220px;
        height: 60px;
        font-size: 20px;
    }

}

@media only screen   and (min-device-width : 1361px)  and (max-device-width : 1440px)  { 

    .investment-section .topright img {
        width: 50%;
    }

    .investment-section .bottomleft img {
        width: 45%;
    }

    .investment-section .topright {
        text-align: right;
        top: calc(-35% - 0px);
        right: 30px;
    }
    
    .investment-section .bottomleft {
        text-align: left;
    }

    .investment-section button {
        width: 220px;
        height: 60px;
        font-size: 20px;
    }

}

@media only screen   and (min-device-width : 1441px)  and (max-device-width : 1600px)  { 

    .investment-section .bottomleft img,
    .investment-section .topright img {
        width: 58%;
    }
    .investment-section .topright {
        text-align: right;
        top: calc(-35% - 0px);
        right: 30px;
    }
    
    .investment-section .bottomleft {
        text-align: left;
    }

    .investment-section button {
        width: 220px;
        height: 60px;
        font-size: 20px;
    }

} */




@media only screen and (min-device-width : 1024px) and (max-device-width : 1280px)  { 

    .embracing-section {
        background-image:  url(../images/embracingbgVision2-mb.webp) ,url(../images/embracingbgVision-mb.webp);
        background-position:bottom right,bottom left;
        background-size: auto 300px, cover !important ;
        background-repeat:  no-repeat ,no-repeat;
        padding: 50px 0px 0px;
    }

    .embracing-section.ar {
        background-image:  url(../images/embracingbgVision2-mb.webp) ,url(../images/embracingbgVision-mb.webp);
        background-position:bottom left,bottom left;
        background-size: auto 300px, cover !important ;
        background-repeat:  no-repeat ,no-repeat;
        padding: 50px 0px 0px;
    }

    .embracing-section h2, .embracing-section .boldheading{
        font-size: 33px;
    }

}



@media only screen and (min-device-width : 1281px) and (max-device-width : 1440px)  { 

    .embracing-section {
        background-image:  url(../images/embracingbgVision2-mb.webp) ,url(../images/embracingbgVision-mb.webp);
        background-position:bottom right,bottom left;
        background-size: auto 350px, cover !important ;
        background-repeat:  no-repeat ,no-repeat;
        padding: 50px 0px 0px;
    }

    .embracing-section.ar {
        background-image:  url(../images/embracingbgVision2-mb.webp) ,url(../images/embracingbgVision-mb.webp);
        background-position:bottom left,bottom left;
        background-size: auto 350px, cover !important ;
        background-repeat:  no-repeat ,no-repeat;
        padding: 50px 0px 0px;
    }

    .embracing-section h2, .embracing-section .boldheading{
        font-size: 33px;
    }

}