@font-face {
  font-family: 'Helvetica';
  font-weight: 400;
  src: local('Helvetica'), url(./font/FreeSans.otf) format('truetype');
}


.App {
  text-align: center;
  /* overflow-x: hidden; */
}

.blank {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  z-index: 20;
}

.mobile {
  display: none;
}

a {
  text-decoration: none !important;
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 768px)  { 
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}