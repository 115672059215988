.value-section{
    background: #EDF1F5;
    padding: 200px 0px 150px;
    position: relative;
    overflow: hidden;
}

.value-section.ar {
    direction: rtl;
}
.value-section .lefttop {
    position: absolute;
    left: 0;
    top: -150px ;
}

.value-section .rightbottom {
    position: absolute;
    right: 0;
    bottom: -5px;
}

.value-section.ar .lefttop {
    position: absolute;
    left: unset;
    right: 0;
    top: -100px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.value-section.ar .rightbottom {
    position: absolute;
    right: unset;
    left: 0;
    top: unset;
    bottom: -5px;
    transform: scaleX(-1);
}

.value-section .value-heading{
    color: #1D273B;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px; /* 225% */

}

.value-section .value-para{
    color: #1D273B;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 140% */
}
/* .value-section img{
    width: 100%;
  
} */
.value-section .bg-box{
    border-radius: 10px;
    background: #28364D;
    backdrop-filter: blur(5px);
    height: 456px;
}
.value-section .inner-div{
    padding: 20px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: top;
}
.value-section .img-div{
    height: 121px;
}
.value-section img{
    padding-top: 30px;
    /* padding-bottom: 20px; */
}
.value-section .img-heading{
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 128%; /* 33.28px */
    padding-top: 40px;
}

/* .value-section.ar .img-heading {
    text-align: right;
}  */

.value-section .img-para{
    color: #C8C8C8;
    text-align: justify;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}
/* .value-section .dflex{
display: flex !important;
} */

.our-value-box {
    max-width: 395px;
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 
   
    .value-section {
        padding: 50px 0px 50px;
    }

    .value-section img {
        padding-top:0px !important;
    }

    .value-section .lefttop {
        top: 0px;
    }

    .value-section .heading-box {
        max-width: 294px;
        margin: auto;
    }

    .value-section .value-heading {
        font-size: 24px;
        line-height: normal;
    }

    .value-section .value-para {
        font-size: 16px;
        line-height: 130%;    
    }

    .value-section .bg-box {
        margin: 20px auto;
        max-width: 298px;
        max-height: 350px;
    }

    /* .value-section .bg-box  .img-div {
        max-height: 72px;
    } */

    .value-section .bg-box .img-div {
        height: auto;
    }

    .value-section .bg-box  .img-div img {
        padding-top: 0px;
        max-height: 67px;
    }

    .value-section .bg-box  .img-heading {
        font-size: 18px;
        line-height: 128%;
        margin-top: 0px !important;
    }

    .value-section .bg-box  .img-para {
        font-size: 14px;
        line-height: 130%; 
    }
}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 
   
    .value-section {
        padding: 50px 0px 50px;
    }

    .value-section .desktop {
        display: none;
    }

    .value-section .mobile {
        display: block;
    }


    .value-section .col-md-4 {
        width: 100%;
        max-width: 760px;
        margin: auto;
    }
    
    .value-section img {
        padding-top:0px;
    }

    .value-section .lefttop {
        top: 0px;
    }

    .value-section .heading-box {
        max-width: 494px;
        margin: auto;
    }

    .value-section .value-heading {
        font-size: 24px;
        line-height: normal;
    }

    .value-section .value-para {
        font-size: 16px;
        line-height: 130%;    
    }

    .value-section .bg-box {
        margin: 20px auto;
        max-width: 358px;
        max-height: 400px;
    }

    /* .value-section .bg-box  .img-div {
        max-height: 72px;
    } */

    .value-section .bg-box .img-div {
        height: auto;
    }

    .value-section .bg-box  .img-div img {
        padding-top: 0px;
        /* max-height: 67px; */
    }

    .value-section .bg-box  .img-heading {
        font-size: 18px;
        line-height: 128%;
        margin-top: 0px !important;
    }

    .value-section .bg-box  .img-para {
        font-size: 14px;
        line-height: 130%; 
    }
}


@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
  
    .value-section .lefttop img ,
    .value-section .rightbottom img { 
        width: 70%;
    }
  
    .value-section .lefttop {
       text-align: left;
        left: 0;
    }
    
    .value-section .rightbottom {
       text-align: right;
        right: 0;
    }

    .value-section .col-md-10 {
        width: 95%;
    }
}


@media only screen   and (min-device-width : 1281px)  and (max-device-width : 1360px)  { 
  
    .value-section .lefttop img ,
    .value-section .rightbottom img { 
        width: 80%;
    }
  
    .value-section .lefttop {
       text-align: left;
        left: 0;
    }
    
    .value-section .rightbottom {
       text-align: right;
        right: 0;
    }

    .value-section .col-md-10 {
        width: 95%;
    }
}


@media only screen   and (min-device-width : 1361px)  and (max-device-width : 1600px)  { 
  
    .value-section .lefttop img ,
    .value-section .rightbottom img { 
        width: 85%;
    }
  
    .value-section .lefttop {
       text-align: left;
        left: 0;
    }
    
    .value-section .rightbottom {
       text-align: right;
        right: 0;
    }

    /* .value-section .col-md-10 {
        width: 95%;
    } */
}