
.rsbody-section {
    min-height: 400px;
    width: 100%;
    background: #EDF1F5;
    position: relative;
}

.rsbody-section.ar {
    direction: rtl;
}

.rsbody-section .text-box {
    color: #FFFFFF;
    width: 100%;
    margin: auto !important;
    max-width: 1200px;
    padding: 60px;
    z-index: 4;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    /* background: linear-gradient(180deg, #1C263A 0%, #253046 100%);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25); */
    border-radius: 20px;
    /* background: #5E83AE; */
    background: linear-gradient(180deg, #5E83AE 0%, #446FA0 52.08%, #507CAE 100%);
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.25);
    /* background: #5E83AE; */
}

.rsbody-section .text-box img {
    width: 100%;
}

.rsbody-section.ar .text-box img {
    transform: scaleX(-1);
}

.topright {
    position: absolute;
    top: 0;
    right: 10px;
}

.bottomleft {
    position: absolute;
    bottom: 0;
    left: 0px;
}

.rsbody-section .text-box h2{
    color: #FFF;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 52px */
    text-align: left;
}

.rsbody-section.ar .text-box h2,
.rsbody-section.ar .text-box p {
    text-align: right;
}

.rsbody-section .text-box p {
    color: #FFF;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;;
}

.rsbody-section .img-para{    
    font-family: 'Vazirmatn';
    font-style: normal;
    font-weight: 400;
    font-size: 22px !important;
    line-height: 34px;
    padding-top: 40px;
    text-align: justify !important;
}


.rs-sets-section {
    width: 100%;
    background: #1D273B;
    position: relative;
    padding-top: 350px;
}

.rs-sets-section.ar {
    direction: rtl;
}

.rs-sets-section .header h2 {
    color: #FFF;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.rs-sets-section .header p {
    color: #C8C8C8;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
}

.rs-sets-section .img-box {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 15px;
    padding: 30px;
    margin: 20px 0px;
    text-align: left;
}

.rs-sets-section.ar .img-box {
    text-align: right;
}

.rs-sets-section .img-box img {
    max-width: 158px;
}

.rs-sets-section .img-box div:first-child {
    margin-right: 30px;
}

.rs-sets-section.ar .img-box div:first-child {
    margin-left: 30px;
}

.rs-sets-section .img-box div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.rs-sets-section .img-box h2 {
    color: #000;
    font-family: Vazirmatn;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
}

.rs-sets-section .img-box p {
    color: #535353;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.rs-sets-section .inner-img-div {
    display: flex;
    justify-content: center;
}
/* .rs-sets-section  .padding-div{
padding: 0px;
} */


@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 
  
    .rsbody-section {
        min-height: 350px;
    }

    .rsbody-section .text-box {
        padding: 15px;
        width: 90%;
        max-width: 540px;
        margin: auto;
        top: 30px;
    }

    .rsbody-section .text-box .image-heading {
        text-align: left;
    }

    .rsbody-section.ar .text-box .image-heading {
        text-align: right;
    }

    .rsbody-section .text-box .image-heading img {
        width: 75%;
    }

    .rsbody-section .text-box h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: 130%; 
        position: absolute;
        width: 35%;
        top: 50px;
        right: 30px;
    }

    .rsbody-section.ar .text-box h2 {
        left: 30px !important;
        right: unset;
    }   

    .rsbody-section .text-box p {
        text-align: justify;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
    }

    .rs-sets-section {
        padding-top: 250px;
    }

    .rs-sets-section .header h2 {
        text-align: center;
        font-size: 24px;
        line-height: normal;
    }
    
    .rs-sets-section .header p {
        font-size: 16px;
        font-weight: 300;
        line-height: 130%; 
    }

    .rs-sets-section .img-box {
        max-width: 540px;
        width: 90%;
        margin: 20px auto;
        padding: 20px;
    }

    .rs-sets-section .img-box {
        max-width: 540px;
        width: 93%;
        margin: 15px auto;
        padding: 10px 20px;
    }

    .rs-sets-section .img-box div:first-child {
        width: 45%;
        margin-right: 25px;
    }

    .rs-sets-section .img-box h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
    }

    .rs-sets-section .img-box p {
        text-align: justify;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
    }
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 350px)  { 
    .rsbody-section .text-box h2 {
        font-size: 18px;  
        top: 40px;  
    }

    .rs-sets-section .img-box {
        display: block;
    }

    .rs-sets-section .img-box div:first-child {
        width: 100%;
        margin: auto auto 20px;
    }

}

@media only screen   and (min-device-width : 351px)  and (max-device-width : 400px)  { 
    .rsbody-section .text-box h2 {
        font-size: 20px; 
        top: 40px;   
    }
}

@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 
  
    .rsbody-section .desktop {
        display: none;
    }

    .rsbody-section .mobile {
        display: block;
    }

    .rsbody-section {
        min-height: 350px;
    }

    .rsbody-section .text-box {
        padding: 25px;
        width: 100%;
        max-width: 720px;
        margin: auto;
        top: 50px;
    }

    .rsbody-section .text-box .image-heading {
        text-align: left;
    }

    .rsbody-section.ar .text-box .image-heading {
        text-align: right;
    }

    .rsbody-section .text-box .image-heading img {
        width: 75%;
    }

    .rsbody-section .text-box h2 {
        font-size: 40px;
        font-weight: 400;
        line-height: 130%;
        position: absolute;
        width: 35%;
        top: 80px;
        right: 50px;
    }

    .rsbody-section.ar .text-box .image-heading h2 {
        right: unset;
        left: 50px;
    }
    
    .rsbody-section .text-box p {
        text-align: justify;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
    }

    .rs-sets-section {
        padding-top: 400px;
    }

    .rs-sets-section .header h2 {
        text-align: center;
        font-size: 24px;
        line-height: normal;
    }
    
    .rs-sets-section .header p {
        font-size: 16px;
        font-weight: 300;
        line-height: 100%; 
    }

    .rs-sets-section .img-box {
        max-width: 720px;
        width: 100%;
        margin: 20px auto;
        padding: 20px;
    }

    .rs-sets-section .img-box div:first-child {
        width: 45%;
        margin-right: 30px;
    }

    .rs-sets-section .img-box h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
    }

    .rs-sets-section .img-box p {
        text-align: justify;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
    }
}


@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
    .rs-sets-section {
       padding-top: 450px;
    }

    .rsbody-section .text-box {
        max-width: 950px;
    }

    .rsbody-section .text-box {
        top: 50px;
    }
}