.aboutdesk-section{
    background-color: #EDF1F5;
    height: 100%; 
    margin-top: 0px; 
    padding: 100px 0px 0px; 
    position: relative;
}

.aboutdesk-section.ar { 
    direction: rtl;
}

.aboutdesk-section.ar .img-heading {
    text-align: right;
}

.aboutdesk-section.ar .sub-para {
    text-align: right;
}
/* .aboutdesk-section .inner-aboutdesk-div{
    opacity: 0.800000011920929;
    background: linear-gradient(132deg, #2B3A52 0%, #2B3A52 100%);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
     position: relative;
     top: 10%;
} */
.aboutdesk-section .img-text {
    color: #FFFFFF;
    width: 100%;
    margin: auto;
    max-width: 1200px;
    padding: 60px;
    margin-bottom: 60px;
    transform: translateY(-20px);

    /* margin-top: -10px; */
    z-index: 4;
    position: relative;
    /* opacity: 0.800000011920929; */
    border-radius: 20px;
    background: linear-gradient(180deg, #5E83AE 0%, #446FA0 38.02%, #507CAE 100%);
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.25);
    /* background: #5E83AE; */
}

.aboutdesk-section .img-heading{
    color: #EDF1F5;
    font-family: Vazirmatn;
    font-size: 60px;
    font-style: normal;
    font-weight: 100;
    line-height: 70px;
    text-align: left;
}

.aboutdesk-section .img-para{
    color: #EDF1F5;
    font-family: Vazirmatn;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transform: translateY(20px);
    text-align: justify !important;
}

.aboutdesk-section .sub-para{
    color: #EDF1F5;
    font-family: Vazirmatn;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: justify;
}

.aboutdesk-section .leftbottom {
    position: absolute;
    left: 0px;
    bottom: 50px;
    overflow-y: hidden;
}

.aboutdesk-section .righttop {
    position: absolute;
    overflow-y: hidden;
    right: 0px;
    top: 0px;  
}

.aboutdesk-section.ar .desktop.col-md-1 {
    display: none;
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {
    .aboutdesk-section {
        padding: 50px 25px 0px;
    }
    .aboutdesk-section .leftbottom {
        bottom: 0px;
    }

    .aboutdesk-section .img-text {
        padding: 15px;
        margin-bottom: 20px;
    }

    .aboutdesk-section .img-heading{
        font-size: 36px;
        line-height: normal;
    }
    
    .aboutdesk-section .img-para{
        font-size: 16px;
        line-height: normal;
        padding-left: 20px;
    }

    .aboutdesk-section .col-md-11 {
        width: 100%;
    }

    .aboutdesk-section .sub-para {
        font-size: 14px;
        line-height: normal;    
    }

}




@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {
    .aboutdesk-section {
        padding: 50px 25px 0px;
    }

    .aboutdesk-section .mobile {
        display: block;
    }

    .aboutdesk-section .desktop {
        display: none;
    }

    .aboutdesk-section .leftbottom {
        bottom: 0;
    }

    .aboutdesk-section .img-text {
        padding: 25px;
        max-width: 720px;
    }

    .aboutdesk-section .img-heading{
        font-size: 36px;
        line-height: normal;
    }
    
    .aboutdesk-section .img-para{
        font-size: 16px;
        line-height: normal;
    }

    .aboutdesk-section .col-md-11 {
        width: 100%;
    }

    .aboutdesk-section .sub-para {
        font-size: 14px;
        line-height: normal;    
    }

}



@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  {
    .aboutdesk-section .leftbottom img,
    .aboutdesk-section .righttop img {
        width: 50%;
    }

    .aboutdesk-section .leftbottom {
        text-align: left;
        bottom: 50px;
    }
    
    .aboutdesk-section .righttop {
        text-align: right;
    }
}

@media only screen   and (min-device-width : 1281px)  and (max-device-width : 1360px)  {
    .aboutdesk-section .leftbottom img,
    .aboutdesk-section .righttop img {
        width: 60%;
    }

    .aboutdesk-section .leftbottom {
        text-align: left;
        bottom: 50px;
    }
    
    .aboutdesk-section .righttop {
        text-align: right;
    }
}


@media only screen   and (min-device-width : 1361px)  and (max-device-width : 1600px)  {
    .aboutdesk-section .leftbottom img,
    .aboutdesk-section .righttop img {
        width: 70%;
    }

    .aboutdesk-section .leftbottom {
        text-align: left;
        bottom: 50px;
    }
    
    .aboutdesk-section .righttop {
        text-align: right;
    }
}