.real-estate-section {
    background: linear-gradient(180deg, rgba(28, 38, 58, 0) 0%, #1C263A 0%, #1C263A 100%);
    /* height: 7101px; */
    padding-bottom: 40px;
    overflow-x: hidden;
}

.real-estate-section.ar {
    direction: rtl;
}


.real-estate-section .real-estate-bg,
.real-estate-section .non-real-estate-bg {
    height: 192px;
    /* left: 0px;
top: 4599px; */
border-radius: 0px 0px 20px 20px;
opacity: 0.800000011920929;
background: #1D273B;
    position: absolute;
    z-index: 10 !important;
}

.real-estate-section .real-estate-heading,
.real-estate-section .non-real-estate-heading {

    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    /* 225% */
    text-transform: capitalize;
    padding-top: 40px;
}

.real-estate-section .real-estate-para,
.real-estate-section .non-real-estate-para {
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    max-width: 1200px;
    margin: auto;
    transform: translateY(-20px);
}

.real-estate-section .real-estate-row1 img,
.real-estate-section .non-real-estate-row1 img {
    width: 110%;
    height: 650px;
    /* margin-left: -15px !important;
    margin-right: -15px !important; */
    margin: 0px;
    cursor: pointer;
    transition: all 0.5s;
}

/* .real-estate-section .real-estate-row1 img:hover,
.real-estate-section .non-real-estate-row1 img:hover {
    transform: scale(1.1);
} */

.real-estate-section .real-estate-row1 .img-box:hover img ,
.real-estate-section .real-estate-row1 .img-box:hover h4 ,
.real-estate-section .non-real-estate-row1 .img-box:hover img,
.real-estate-section .non-real-estate-row1 .img-box:hover h4 {
    transform: scale(1.1);
}

.real-estate-section .real-estate-row1 .col,
.real-estate-section .non-real-estate-row1 .col {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.real-estate-section .real-estate-row1 .img-box,
.real-estate-section .non-real-estate-row1 .img-box {
    overflow: hidden;
    position: relative;
}


/* .real-estate-row1{
    margin: 0px;
} */
.real-estate,
.non-real-estate {
    padding-bottom: 140px;
    padding: 0px 0px 140px;
    margin-bottom: 0px;
    position: relative;
}

/* .real-estate-row1 h4,  */

.real-estate-section button {
    box-sizing: border-box;
    width: 244px;
    height: 64px;
    text-align: center !important;
    justify-content: center;
    background: #D2433D;
    border: 1px solid #A22222;
    color: #FFFFFF;
    align-items: center;
    position: relative;
    top: 120%;
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 166.667% */
    /* margin-top: 50px !important; */
}

.non-real-estate button {
    position: relative;
    top: 120%;
}

.real-estate-section .img-text {
    font-family: Vazirmatn;
    font-size: 25px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    position: relative;
    position: absolute;
    padding: 20px 50px;
    bottom: 0;
    transition: all 0.5s;
    width: 100%;
}



@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 
    .real-estate-section {
        background: #28364D;
    }

    .real-estate-section .real-estate-bg, .real-estate-section .non-real-estate-bg {
        position: relative;
        height: auto;
        opacity: 1;
        margin: 20px 0;
        border-radius: 0;
    }

    .real-estate {
        padding: 0px 0px 60px;
    }

    .non-real-estate {
        padding: 0px 0px 30px;
    }

    .real-estate-row1.row,  
    .non-real-estate-row1.row  {
        --bs-gutter-x: 0px;
    }

    .real-estate .real-estate-row1 .col-md-3, 
    .non-real-estate .non-real-estate-row1 .col-md-3 {
        width: 50%;
    }

    .real-estate-section .real-estate-row1 img,
    .real-estate-section .non-real-estate-row1 img {
        max-height: 350px;
    }

    .real-estate-section .real-estate-heading, .real-estate-section .non-real-estate-heading {
        font-size: 24px;
        line-height: 130%;
        max-width: 400px;
        margin: auto;
        padding-bottom: 40px;
    }

    .real-estate-section .real-estate-para, .real-estate-section .non-real-estate-para {
        font-size: 16px;
        line-height: 130%;
    }

    .real-estate-section .img-text {
        font-size: 16px;
        line-height: 130%; 
        text-align: center;
        padding: 20px;
    }

    .real-estate button ,
    .non-real-estate button {
        position: relative;
        top: 80%;
        width: 180px;
        height: 48px;
        color: #FFF;
        font-size: 16px;
        line-height: 250%;
    }

 }

 @media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 
    .real-estate-section .real-estate-bg, .real-estate-section .non-real-estate-bg {
        position: relative;
        height: auto;
        opacity: 1;
    }

    .real-estate-section .col-md-3 {
        max-width: 50%;
    }

    .real-estate, .non-real-estate {
        padding: 0px 0px 140px;
    }

    .real-estate-row1.row,  
    .non-real-estate-row1.row  {
        --bs-gutter-x: 0px;
    }

    .real-estate .real-estate-row1 .col-md-3, 
    .non-real-estate .non-real-estate-row1 .col-md-3 {
        width: 50%;
    }

    .real-estate-section .real-estate-row1 img,
    .real-estate-section .non-real-estate-row1 img {
        width: 100%;
        height: auto;
        max-height: 600px;
    }

    .real-estate-section .real-estate-heading, .real-estate-section .non-real-estate-heading {
        font-size: 24px;
        line-height: 130%;
        max-width: 400px;
        margin: auto;
        padding-bottom: 40px;
    }

    .real-estate-section .real-estate-para, .real-estate-section .non-real-estate-para {
        font-size: 16px;
        line-height: 130%;
    }

    .real-estate-section .img-text {
        font-size: 16px;
        line-height: 130%; 
        text-align: center;
        padding: 20px;
    }

    .real-estate button ,
    .non-real-estate button {
        position: relative;
        top: 120%;
        width: 180px;
        height: 48px;
        color: #FFF;
        font-size: 16px;
        line-height: 250%;
    }

 }


 @media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
    .real-estate-section .real-estate-row1 img, .real-estate-section .non-real-estate-row1 img {
        height: 550px;
    }
}

@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1600px)  { 
    .real-estate-section button {
        width: 220px;
        height: 60px;
        font-size: 20px;
    }
}


