.mxbadvantage-section{
    background: #EDF1F5;
    position: relative;
    padding: 50px 0px 100px;
}

.mxbadvantage-section.ar {
    direction: rtl;
}

.mxbadvantage-section .mxbadv-heading{
    color: #1D273B;
    text-align: center;
    font-size: 40px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 700;
    line-height: 90px;
    text-transform: capitalize;
    padding-top: 1rem;
}
.mxbadvantage-section .inner-div{
    text-align: left;
    padding: 0px 10px 0px 10px;
}
/* .mxbadvantage-section img{
    width: 79.543px;

} */

.mxbadvantage-section .mxbadv-div {
    max-width: 350px;
    margin: auto;
}

.mxbadvantage-section .img-heading{
    color: #001833;
    font-size: 24px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.mxbadvantage-section.ar .img-heading {
    text-align: center;
}

.mxbadvantage-section .img-para{
    color: #001833;
    text-align: justify;
    font-size: 16px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
}

.mxbadvantage-section.ar .img-para{
    text-align: center;
}

.mxbadvantage-section .bottomright {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.mxbadvantage-section .topleft {
    position: absolute;
    top: 0px;
    left: 0px;
}


@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 
    .mxbadvantage-section {
        padding: 20px 0px;
    }

    .mxbadvantage-section .topleft {
        top: 0px;
        left: unset;
        right: 0;
    }

    .mxbadvantage-section .bottomright {
        bottom: 0px;
        right: 0px;
    }

    .mxbadvantage-section .mxbadv-div {
        max-width: 520px;
        margin: 20px auto;
    }
    
    .mxbadvantage-section .mxbadv-heading {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 375%;
        padding-top: 0;
    }

    .mxbadvantage-section .img-heading {
        font-size: 18px;
        text-align: center;
        line-height: normal;
    }

    .mxbadvantage-section .img-para{
        font-size: 16px;
        text-align: center;
    }

}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 
    .mxbadvantage-section {
        padding: 20px 0px;
    }

    .mxbadvantage-section .topleft {
        position: absolute;
        top: 0px;
        left: unset;
        right: 0;
    }

    .mxbadvantage-section .desktop {
        display: none;
    }

    .mxbadvantage-section .mobile {
        display: block;
    }


    .mxbadvantage-section .col-md-4 {
        max-width: 720px;
        width: 100%;
        margin: auto;
    }

    .mxbadvantage-section .mxbadv-div {
        max-width: 520px;
        margin: 20px auto;
    }
    
    .mxbadvantage-section .mxbadv-heading {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 375%;
        padding-top: 0;
    }

    .mxbadvantage-section .img-heading {
        font-size: 18px;
        text-align: center;
        line-height: normal;
    }

    .mxbadvantage-section .img-para{
        font-size: 16px;
        text-align: center;
    }

}



@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
    
    .mxbadvantage-section .topleft img ,
    .mxbadvantage-section .bottomright img {
        width: 70%;
    }

    .mxbadvantage-section .topleft {
        text-align: left;
    }

    .mxbadvantage-section .bottomright {
        text-align: right;
    }
}