.explore-realestate-section {
    background: #1D273B;
    position: relative;
}

.explore-realestate-section.ar {
    direction: rtl;
}

.explore-section .container {
    position: relative;
}

.explore-realestate-section .explore-real-image {
    padding-top: 150px;
    padding-bottom: 150px;
    width:100%
}

.explore-realestate-section.ar .explore-real-image {
    transform: scaleX(-1);
}

.explore-realestate-section .explore-real-container .col-md-7 {
    text-align: left;
}

.explore-realestate-section.ar .explore-real-container .col-md-7 {
    text-align: right;
}

.explore-real-container .explore-real-heading {
    color: #FFF;
    font-size: 60px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
}

.explore-real-container .contact-btn {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    border: 1px solid #A12222;
    background: #D2433D;
    width: 244px;
    height: 64px;
    flex-shrink: 0;
}

.explore-realestate-section .container {
    position: relative;
}

.explore-real-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 

    .explore-realestate-section {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .explore-realestate-section .img-text{
        position: unset;
        padding: 20px 0px;
        text-align: center;
    }

    .explore-realestate-section .explore-real-image {
        padding-top: 0px;
        padding-bottom: 20px;
    }

    .explore-realestate-section .img-text .explore-real-heading {
        color: #FFF;
        text-align: center;
        font-family: Vazirmatn;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 130%;          
    }

    .explore-realestate-section .img-text .img-text-para {
        font-size: 20px;
        line-height: 130%;
        text-align: center;
    }

    .explore-realestate-section .img-text .contact-btn {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 250%;
        width: 180px;
        height: 48px;
        text-align: center;
        font-family: Helvetica;
        color: #ffffff;
        border: 1px solid #A12222;
        background: #D2433D;
    }

}


@media only screen   and (min-device-width : 767px)  and (max-device-width : 1023px)  { 
    
    .explore-realestate-section {
        padding-bottom: 0px;
    }

    .explore-realestate-section.mobile {
        display: block;
    }

    .explore-realestate-section.desktop {
        display: none;
    }

    /* .career-section img {
        width: 100vw;
    } */

    .explore-realestate-section .img-text{
        position: unset;
        padding: 20px 0px;
        margin: auto;
        text-align: center;
        max-width: 720px;
        width: 100%;
    }


    .explore-realestate-section .img-text .col-md-6 {
        max-width: 100%; 
        width: 100%;
    }

    .explore-realestate-section .explore-real-image {
        padding-top: 30px;
        padding-bottom: 30px;
    }


    .explore-realestate-section .img-text .explore-real-heading {
        color: #FFF;
        text-align: center;
        font-family: Vazirmatn;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 130%;     
    }

    .explore-realestate-section .img-text .img-text-para {
        font-size: 20px;
        line-height: 130%;
        text-align: center;
    }

    .explore-realestate-section .img-text .contact-btn {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 250%;
        width: 180px;
        height: 48px;
        text-align: center;
        font-family: Helvetica;
        color: #ffffff;
        border: 1px solid #A12222;
        background: #D2433D;
    }

}
/* 
@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
    .explore-realestate-section .img-text {
        width: 85%;
    } 

    .explore-realestate-section .img-text .img-text-heading {
        line-height: 120%;
    }
} */


@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1600px)  { 
    .explore-real-container .contact-btn {
        width: 220px;
        height: 60px;
        font-size: 20px;
    }
}