.homeheader-section{
    background-image: url(../images/homeheaderbg.webp);
    background-repeat: no-repeat;
    background-size: cover !important;
    height: 100%;
    min-height: 100vh;
}

.homeheader-section .header-heading{
    
    font-family: 'Vazirmatn';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 117%;
    text-align: center !important;
    text-transform: uppercase;

    color: #FFFFFF;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {
    .homeheader-section{
        background-image: url(../images/homeheaderbg-mb.webp);
        background-repeat: no-repeat;
        background-size: cover !important;
        height: 100%;
    }

    .homeheader-section .header-heading{
        color: #EDF1F5;
        text-align: center;
        font-family: Vazirmatn;
        font-size: 36px;
        font-style: normal;
        line-height: normal;
        text-transform: uppercase;
    }
}

@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {
    .homeheader-section{
        background-image: url(../images/homeheaderbg-mb.webp);
        background-repeat: no-repeat;
        background-size: cover !important;
        height: 100%;
    }

    .homeheader-section .header-heading{
        color: #EDF1F5;
        text-align: center;
        font-family: Vazirmatn;
        font-size: 36px;
        font-style: normal;
        line-height: normal;
        text-transform: uppercase;
    }
}
