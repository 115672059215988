.careerheader-section {
    background-image: url(../images/career-header-bg.webp);
    background-repeat: no-repeat;
    background-size: cover !important;
    height: auto;
    min-height: 100vh;
    /* overflow-x: hidden; */


}

.careerheader-section .career-heading {
    color: #FFF;
    text-align: center;
    font-size: 60px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
    text-transform: uppercase;
    position: absolute;
    max-width: 800px;
    top: 40% !important;
    left: 50%;
    transform: translateX(-50%);
}

.careerbotheader-section {
    background: linear-gradient(180deg, #5E83AE 0%, #446FA0 47.40%, #507CAE 100%);
}

.careerbotheader-section.ar {
   direction: rtl; 
}


.careerbotheader-section .text-box {
    max-width: 825px;
    width: 100%;
    margin: auto;
}


.careerbotheader-section .career-btm-heading {
    color: #FFF;
    font-size: 60px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
}



.careerbotheader-section .career-btm-para {
    color: #FFF;
    text-align: justify;
    font-size: 22px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* margin-left:90px ; */
}

.careerbotheader-section.ar .career-btm-heading {
    text-align: right;
}

.careerbotheader-section.ar .career-btm-para {
    text-align: center;
    padding-right: 50px;
}

.careerbotheader-section .positn-relative {
    position: relative;
    padding-bottom: 3rem;
    padding-top: 3rem;
    margin-top: 3rem;
    margin-bottom: 0.5rem;
}


@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 

    .careerbotheader-section .positn-relative {
        position: relative;
        padding-bottom: 0rem;
        padding-top: 0rem;
        margin-top: 3rem;
        margin-bottom: 0.5rem;
    }

    .careerheader-section .career-heading {
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;    
    }
    
    .careerbotheader-section.ar .career-btm-para {
        text-align: justify;
        padding-right: 30px;
    }

    .careerbotheader-section .text-box {
        padding-left: 15px;
        padding-right: 15px;
    }

    .careerbotheader-section .career-btm-heading {
        font-size: 36px;
        line-height: 130%;
        text-align: left;
    }

    .careerbotheader-section .career-btm-para {
        font-size: 16px;
        line-height: 130%;
        padding-left: 30px;
    }
    
}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 

    .careerbotheader-section .positn-relative {
        position: relative;
        padding-bottom: 2rem;
        padding-top: 2rem;
        margin-top: 3rem;
        margin-bottom: 0.5rem;
    }

    .careerheader-section .career-heading {
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;    
    }

    .careerbotheader-section .text-box {
        padding-left: 15px;
        padding-right: 15px;
    }

    .careerbotheader-section .career-btm-heading {
        font-size: 36px;
        line-height: 130%;
        text-align: left;
    }

    .careerbotheader-section .career-btm-para {
        font-size: 16px;
        line-height: 130%;
        padding-left: 30px;
    }
    
}