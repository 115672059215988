.growmxb-section {
    opacity: 0.8999999761581421;
    background: url(../images/growwithmxb-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 150px !important;
    margin-bottom: auto;
}

.growmxb-section.ar {
    direction: rtl  ;
}

.growmxb-section .growmxb-heading {
    color: #FFF;
    text-align: center;
    font-size: 40px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 50px;
}

.growmxb-section .growmxb-sub-heading {
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    text-transform: capitalize;
}

.growmxb-section .img-heading {
    color: #FBF9F9;
    font-size: 26px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    padding-top: 20px;
}

.growmxb-section .img-para {
    color: #D1D2DF;
    font-size: 18px;
    font-family: DM Sans !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 50px;
}

.growmxb-section a {
    color: #C8C8C8;
    text-align: center;
    font-size: 18px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 40px;
    position:relative;
    text-align: left;
    position: relative;
    display: inline-block;
    left: 0%;
}

.growmxb-section a:hover,
.growmxb-section a:focus {
    color: #C8C8C8;
}

.growmxb-section a::after {
    content: '';
    position: absolute;
    background: #D2433D;
    width: 60.069px;
    height: 2px;
    top: 100%;
    left: 40%;
    transform: translateX(-50%);
    z-index: 4;
}

.growmxb-section.ar a::after {
    left: unset;
    right: 5%;
    transform: translateX(0%);
}

.growmxb-section .bggrey {
    border-radius: 10px;
    border: 1px solid #FFF;
    /* background: #FFF; */
    background-blend-mode: multiply;
    backdrop-filter: blur(10px);
    padding: 0px 5px 0px 5px;
    height: 441px;
    position: relative;
    text-align: left;

    max-height: 670px;
    height: 100%;
}

.growmxb-section.ar .bggrey {
    text-align: right;
}

.growmxb-section .inner-div {
    padding: 20px 20px 50px;
}

.growmxb-section svg {
    transform: translateX(15px);
}

.growmxb-section.ar svg {
    transform: scale(-1);
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 

    .growmxb-section {
        opacity: 0.8999999761581421;
        background: url(../images/growwithmxb-bg-mb.webp);
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 150px !important;
        margin-bottom: auto;
    }

    .growmxb-section .growmxb-heading {
        font-size: 24px;
        line-height: normal;
    }

    .growmxb-section .growmxb-sub-heading {
        font-size: 18px;
        line-height: normal;
    }

    .growmxb-section .bggrey {
        max-width: 540px;
        width: 100%;
        max-height: 400px;
        margin: 20px auto;
    }

    .growmxb-section .col-md-4 {
        max-width: 540px;
        width: 100%;
        margin: auto;
    }

    .growmxb-section .img-heading {
        font-size: 18px;
        line-height: normal;
        padding: 0px;
    }

    .growmxb-section .img-para {
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        padding-top: 20px;
    }

    .growmxb-section a {
        font-size: 16px;
        line-height: normal;
        padding-top: 20px;
    }
}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 

    .growmxb-section {
        opacity: 0.8999999761581421;
        background: url(../images/growwithmxb-bg-mb.webp);
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 150px !important;
        margin-bottom: auto;
    }

    .growmxb-section .growmxb-heading {
        font-size: 24px;
        line-height: normal;
    }

    .growmxb-section .growmxb-sub-heading {
        font-size: 18px;
        line-height: normal;
    }

    .growmxb-section .bggrey {
        max-width: 720px;
        width: 100%;
        max-height: 250px;
        margin: 20px auto;
    }

    .growmxb-section .col-md-4 {
        max-width: 720px;
        width: 100%;
        margin: auto;
    }


    .growmxb-section .img-heading {
        font-size: 18px;
        line-height: normal;
        padding: 0px;
    }

    .growmxb-section .img-para {
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        padding-top: 20px;
    }

    .growmxb-section a {
        font-size: 16px;
        line-height: normal;
        padding-top: 20px;
    }
}

@media only screen   and (min-device-width : 1023px)  and (max-device-width : 1280px)  {
    .growmxb-section .img-para {
        padding-top: 30px;
    }

    .growmxb-section a {
        padding-top: 30px;
    }
}


@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1199px)  {
    .growmxb-section .bggrey {   
        max-height: 670px;
        height: 100%;
    }

}