.termsheader-section {
    background-image: url(../images/termsheader-bg.webp);
    background-repeat: no-repeat;
    background-size: cover !important;
    height: auto;
    min-height: 100vh;
    /* overflow-x: hidden; */
}

.termsheader-section h1 {
    color: #FFF;
    text-align: center;
    font-size: 60px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
    text-transform: uppercase;
    position: absolute;
    top: 40%;
    left: 0%;
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {
    .termsheader-section {
        background-image: url(../images/termsheader-bg-mb.webp);
    }

    .termsheader-section h1 {
        font-size: 36px;
        line-height: normal;
        top: 30%;
        max-width: 300px;
        margin: auto;
        left: 50%;
        transform: translateX(-50%);
    }
    
}

@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {
    .termsheader-section {
        background-image: url(../images/termsheader-bg-mb.webp);
    }

    .termsheader-section h1 {
        font-size: 36px;
        line-height: normal;
        top: 40%;
        max-width: 300px;
        margin: auto;
        left: 50%;
        transform: translateX(-50%);
    }
    
}

