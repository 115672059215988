

.keyproject-section {
    width: 100%;
    background: #EDF1F5;
    position: relative;
    padding: 80px 0px 150px;
}

.keyproject-section.ar {
    direction: rtl;
}

.keyproject-section .topright {
    position: absolute;
    top: 0;
    right: 50px;
    text-align: right;
    z-index: 0;
}

.keyproject-section .bottomleft {
    position: absolute;
    bottom: 80px;
    left: 0px;
    text-align: left;
    z-index: 0;
}


.keyproject-section .header h2 {
    color: #1D273B;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 90px;
    text-transform: capitalize;
}

.keyproject-section .header p {
    color: #1D273B;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 30px;
    line-height: 90px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    position: relative;
}

.keyproject-section.ar .header p {
    line-height: 50px;
}

.keyproject-section .header p:after {
    content: "";
    width: 126px;
    height: 2px;
    background: #D2433D;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}

.keyproject-section .container {
    position: relative;
    z-index:2;
}


.keyproject-section .img-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 8px;
    background: #1D273B;
    text-align: left;
    height: 100%;
}

.keyproject-section .img-box img {
    width: 100%;
}

.keyproject-section .img-box div:last-child {
    padding: 35px;
}

.keyproject-section .img-box h2 {
    color: #FBF9F9;
    text-align: center;
    font-family: Vazirmatn;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.keyproject-section .img-box p {
    color: #D1D2DF;
    text-align: justify;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.keyproject-section .text-box .img-box .undev-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.keyproject-section .text-box .undev-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyproject-section .text-box .undev-left h2 {
    color: #1D273B;
    font-family: Vazirmatn;
    font-size: 30px;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    line-height: 130%;
    text-transform: capitalize;
    position: relative;
}

.keyproject-section .text-box .undev-left h2:after { 
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 126px;
    height: 2px;
    background: #D2433D;
}

.keyproject-section.ar .text-box .undev-left h2:after { 
    right: 0;
}


.keyproject-section .text-box .undev-right {
    border-radius: 8px;
    background: #1D273B;
}

.keyproject-section .text-box .undev-right img {
    width: 100%;
    height: 100%;
    border-radius: 8px 0 0;
}
.keyproject-section .text-box .undev-right h2 {
    color: #FBF9F9;
    font-family: Vazirmatn;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.keyproject-section .text-box .undev-right p {
    color: #D1D2DF;
    text-align: justify;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.keyproject-section .d-flex.prow-2 {
    width: 100%;
    gap: 50px;
}

.keyproject-section .d-flex.prow-2 .pro1,
.keyproject-section .d-flex.prow-2 .pro2 {
    width: 50%;
    border-radius: 8px;
}

.keyproject-section .d-flex.prow-2 h2 {
    text-align: left;
}

.keyproject-section .d-flex.prow-2 .text-box {
    padding: 15px 20px 10px;
}


.keyproject-section .infra-prjects .img-box {
    position: relative;
    border-radius: 8px;
}

.keyproject-section .infra-prjects .img-box img {
    border-radius: 8px 8px 0 0;
}

.keyproject-section .infra-prjects .img-box .logo-box img {
    border-radius: 0px;
}

.keyproject-section .infra-prjects .img-box .logo-box {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 15px;
}

.keyproject-section .infra-prjects .img-box .text-box {
    color:#ffffff;
}

.keyproject-section .infra-prjects .img-box .text-box .grid-2 {
    display: grid;
    grid-template-columns: 35% 65%;
    padding: 0px;
}

.keyproject-section .infra-prjects .img-box .text-box .grid-2 div {
    padding: 10px 0px;
    color: #D1D2DF;
    font-size: 18px;
    font-weight: 400;
}

.keyproject-section .infra-prjects .img-box .text-box .grid-2 div span::before {
    content: ":";
    padding-right: 10px;
}

.keyproject-section .infra-prjects .img-box .text-box .grid-2 div:nth-child(2n+1) {
    font-weight: 700;
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {

    .keyproject-section {
        padding: 30px 0px 0px;
    }

    .keyproject-section .topright {
        right: 0;
    }

    .keyproject-section .bottomleft {
        bottom: 0;
    }

    .keyproject-section .header h2 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; 
    }

    .keyproject-section .header p {
        font-size: 20px;
        font-style: normal;
        margin-top: 20px;
        font-weight: 400;
        line-height: 100%;
    }

    .keyproject-section .header p:after {
        bottom: -20px;
    }

    .keyproject-section .img-box h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    .keyproject-section .img-box p {
        text-align: justify;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .keyproject-section .img-box {
        display: block;
        height: auto; 
        margin: 20px auto;
    }

    .keyproject-section .text-box .undev-left h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
        text-align: center;
    }

    .keyproject-section .text-box .undev-left h2:after {
        left: 50%;
        transform: translateX(-50%);
    }

    .keyproject-section .d-flex.prow-2 {
        flex-direction: column;
    }

    .keyproject-section .d-flex.prow-2 .pro1, 
    .keyproject-section .d-flex.prow-2 .pro2 {
        width: 100%;
    }


    .keyproject-section .d-flex.prow-2 .pro1 .row, 
    .keyproject-section .d-flex.prow-2 .pro2 .row {
        --bs-gutter-x: 0px;
    }

    .keyproject-section .d-flex.prow-2 .pro1 .col-md-4,
    .keyproject-section .d-flex.prow-2 .pro2 .col-md-4 {
       width: 33.33%;
    }

    .keyproject-section .d-flex.prow-2 .pro1 .col-md-8,
    .keyproject-section .d-flex.prow-2 .pro2 .col-md-8 {
        width: 66%;
    }
    
    
    .keyproject-section .text-box .undev-right h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
    }

    .keyproject-section .text-box .undev-right p {
        text-align: justify;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
    }
}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {

    .keyproject-section {
        padding: 80px 0px 50px;
    }


    .keyproject-section .desktop {
        display: none;
    }

    .keyproject-section .mobile {
        display: block;
    }

    .keyproject-section .topright {
        right: 0;
    }

    .keyproject-section .bottomleft {
        bottom: 0;
    }


    .keyproject-section .col-md-4,
    .keyproject-section .col-md-3,
    .keyproject-section .col-md-9 {
        width: 100%;
        max-width: 720px;
        margin: auto;    
    }

    .keyproject-section .d-flex.prow-2 .pro1 .col-md-4,
     .keyproject-section .d-flex.prow-2 .pro2 .col-md-4 {
        width: 33.33%;
     }

    .keyproject-section .header h2 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; 
    }

    .keyproject-section .header p {
        font-size: 20px;
        font-style: normal;
        margin-top: 20px;
        font-weight: 400;
        line-height: 100%;
    }

    .keyproject-section .header p:after {
        bottom: -20px;
    }

    .keyproject-section .img-box h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
    }

    .keyproject-section .img-box p {
        text-align: justify;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .keyproject-section .img-box {
        display: block;
        height: auto; 
        margin: 20px auto;
    }

    .keyproject-section .text-box .undev-left h2 {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
    }

    .keyproject-section .d-flex.prow-2 {
        flex-direction: column;
    }

    .keyproject-section .d-flex.prow-2 .pro1, 
    .keyproject-section .d-flex.prow-2 .pro2 {
        width: 100%;
    }

    .keyproject-section .d-flex.prow-2 .pro1 .row, 
    .keyproject-section .d-flex.prow-2 .pro2 .row {
        --bs-gutter-x: 0px;
    }

    .keyproject-section .text-box .undev-right h2 {
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
    }

    .keyproject-section .text-box .undev-right p {
        text-align: justify;
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
    }
}

@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  {
    .keyproject-section .topright img,
    .keyproject-section .bottomleft img {
        width: 70%;
    }
   
    .keyproject-section .topright {
        text-align: right;
    }

    .keyproject-section .bottomleft {
        text-align: left;
    }
}

@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
    .keyproject-section .topright img,
    .keyproject-section .bottomleft img {
        width: 50%;
    }
}

@media only screen   and (min-device-width : 1281px)  and (max-device-width : 1360px)  { 
    .keyproject-section .topright img,
    .keyproject-section .bottomleft img {
        width: 60%;
    }
}