.realestateheader-section {
    background-image: url(../images/rs-header-bg.webp);
    background-repeat: no-repeat;
    background-size: cover !important;
    height: auto;
    min-height: 100vh;
    /* overflow-x: hidden; */


}

.realestateheader-section .realestate-heading {
    color: #FFF;
    text-align: center;
    font-size: 60px;
    font-family: Vazirmatn;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
    text-transform: uppercase;
    max-width: 800px;
    position: absolute;
    top: 40% !important;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;

}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  {
    .realestateheader-section .realestate-heading {
        font-size: 36px;
        line-height: 130%; 
        max-width: 400px;    
    }
}

@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  {
    .realestateheader-section .realestate-heading {
        font-size: 36px;
        line-height: 130%; 
        max-width: 450px;    
    }
}