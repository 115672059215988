.career-section { 
    background: #EDF1F5;
    padding-bottom: 80px;
    position: relative;
    /* height: auto;
    min-height: 80vh; */

}
/* .career-section .career-image{
    position: absolute;
    left: 20%;
    margin-bottom: -30px;
    padding-bottom: 40px;
} */

.career-section.ar {
    direction: rtl; 
}
.career-section.ar .career-image {
    transform: scaleX(-1);
}

.career-section.ar .img-text .col-md-6 {
    margin-left: 50%;
}

.career-section .img-text{
    position: absolute;
    z-index: 2;
    text-align: justify;
    width: 60%;
    top: 240px;
    left: 210px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.career-section .img-text .img-text-heading{
    color: #1C263A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px; /* 150% */
}
.career-section .img-text .img-text-para{
    color: #1C263A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Vazirmatn;
    font-size: 30px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 45px */
}
.career-section .img-text .img-text-button{
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    border: 1px solid #A12222;
    background: #D2433D;
    width: 244px;
    height: 64px;
    flex-shrink: 0;
}



@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 
    .career-section {
        padding-bottom: 0px;
    }

    .career-section .img-text{
        position: unset;
        padding: 20px 0px;
        text-align: center;
        width: 100%;
        transform: translate(0,0);
    }

    .career-section .img-text .img-text-heading {
        font-size: 36px;
        line-height: 130%;
        text-align: center;
        
    }

    .career-section .img-text .img-text-para {
        font-size: 20px;
        line-height: 130%;
        text-align: center;
    }

    .career-section .img-text .img-text-button {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 250%;
        width: 180px;
        height: 48px;
        text-align: center;
    }


}


@media only screen   and (min-device-width : 767px)  and (max-device-width : 1023px)  { 
    .career-section {
        padding-bottom: 0px;
    }

    .career-section.mobile {
        display: block;
    }

    .career-section.desktop {
        display: none;
    }

    /* .career-section img {
        width: 100vw;
    } */

    .career-section .img-text{
        position: unset;
        padding: 20px 0px;
        text-align: center;
        max-width: 720px;
        width: 100%;
        margin: auto;
        transform: translate(0,0);
    }


    .career-section .img-text .col-md-6 {
        max-width: 100%; 
        width: 100%;
    }

    .career-section .img-text .img-text-heading {
        font-size: 36px;
        line-height: 130%;
        text-align: center;
        
    }

    .career-section .img-text .img-text-para {
        font-size: 20px;
        line-height: 130%;
        text-align: center;
    }

    .career-section .img-text .img-text-button {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 250%;
        width: 180px;
        height: 48px;
        text-align: center;
    }


}



@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
    .career-section .img-text {
        width: 85%;
    }

    .career-section .img-text .img-text-heading {
        line-height: 120%;
    }
}


@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1600px)  { 
    .career-section .img-text .img-text-button{
        width: 220px;
        height: 60px;
        font-size: 20px;
    }
}