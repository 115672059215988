.footer-section.ar {
    direction: rtl;
}

.footer-section.mobile.ar .social-media-icon .icon-box {
    margin-right: auto;
    margin-left: unset;
}

.footer-section .footer-container {
    /* height: 258px; */
    position: relative !important;
    overflow-y: hidden;
}

.footer-section .top-footer {
    background: #17181B;
    /* padding-top: 40px; */
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative;
    top: 10%; */
}

.footer-section .menu-container ul {
    display: flex;
}

.footer-section  .logo img{
    width: 250px;
    height: auto;
    /* padding-left: 20px; */
}

.footer-section .menu-container ul li {
    list-style-type: none;
    justify-content:space-around;
    margin: auto;
}

.footer-section .menu-container ul li a {
    color: #FFF;
    font-size: 16px;
    font-family: Vazirmatn !important;
    font-weight: 300;
}

.footer-section .social-media-icon ul{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
}

.footer-section.ar .social-media-icon ul {
    direction: ltr;
}

.footer-section .social-media-icon ul li {
    list-style-type: none;
    justify-content: space-between;
    margin: 8px 8px;
}
.footer-section .social-media-icon .social-media-div{
    color: #FFF;
    font-size: 12px;
    font-family: Vazirmatn;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 20px;
}
.footer-section .middle-footer {
    background: #000 !important;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-section .copyright-text{
    color: #707070;
    text-align: center;
    font-size: 12px;
    font-family: Nunito;
    font-weight: 300;
}
/* .footer-section .bottom-footer{
    background: linear-gradient(180deg, rgba(28, 38, 58, 0.00) 0%, #1C263A 0%, #1C263A 100%);
    padding-bottom: 78px;
} */



@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 

    .footer-section .footer-container {
        background: #17181B;
        padding: 50px 20px 10px;
    }

    .footer-section .top-footer {
        height: unset;
        padding-top: 20px;
        padding-bottom: 40px;
    } 

    .footer-section .footer-container .links-row,
    .footer-section .footer-container .social-media-icon  {
        max-width: 480px;
        margin: auto;
    }

    .footer-section .menu-container ul {
        display: block;
    }

    .footer-section .menu-container ul li {
        text-align: left;
        margin: 15px auto;
    }

    .footer-section .social-media-icon .social-media-div {
        display: block;
        font-size: 10px;
    }

    .footer-section .social-media-icon .icon-box {
        max-width: 198px;
        margin-left: auto;
    }
}

@media only screen   and (min-device-width : 320px)  and (max-device-width : 480px)  { 
   .footer-section .logo img {
        width: 150px;
    }
  
    .footer-section .menu-container ul li a {
        font-size: 12px;
    }
    
    .footer-section .social-media-icon .social-media-div {
        font-size: 10px;
    }

    .footer-section .copyright-text {
        font-size: 10px;
    }
}

@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 

    footer.desktop {
        display: none;
    }

    footer.mobile {
        display: block;
    }

    .footer-section .footer-container {
        background: #17181B;
        padding: 50px 20px 10px;
    }

    .footer-section .top-footer {
        height: unset;
        padding-top: 20px;
        padding-bottom: 40px;
    } 

    .footer-section .footer-container .links-row,
    .footer-section .footer-container .social-media-icon  {
        max-width: 480px;
        margin: auto;
    }

    .footer-section .menu-container ul {
        display: block;
    }

    .footer-section .menu-container ul li {
        text-align: left;
        margin: 15px auto;
    }

    .footer-section .social-media-icon .icon-box {
        max-width: 200px;
        margin-left: auto;
    }
}


@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
    .footer-section .logo img {
        max-width: 200px;
    }

    .footer-section .menu-container ul {
        margin-bottom: 0px;
    }

    .footer-section .social-media-icon ul li {
        margin: 5px;
    }

    .footer-section .social-media-icon ul li img {
        max-width: 27px;
    }

    .footer-section .menu-container ul li a {
        font-size: 12px;
    }

    .footer-section .social-media-icon .social-media-div {
        font-size: 10px;
    }
}