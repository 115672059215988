.aboutmanaging-section{
    background: linear-gradient(180deg, rgba(28, 38, 58, 0) 0%, #1C263A 0%, #1C263A 100%);
    height: 100%; 
    margin-top: 0px; 
    padding: 100px 0px 0px; 
    overflow: hidden;
    position: relative;
}

.aboutmanaging-section.ar {
    direction: rtl;
}

.aboutmanaging-section.ar .img-sub-heading, .aboutmanaging-section.ar .img-heading {
    text-align: right;
}

/* .aboutmanaging-section .inner-aboutdesk-div{
    opacity: 0.800000011920929;
    background: linear-gradient(132deg, #2B3A52 0%, #2B3A52 100%);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
     position: relative;
     top: 10%;
} */
.aboutmanaging-section .img-text {
    color: #FFFFFF;
    width: 100%;
    margin: auto;
    max-width: 1200px;
    padding: 60px;
    margin-bottom: 60px;
    transform: translateY(-20px);
    /* margin-top: -10px; */
    z-index: 4;
    position: relative;
    border-radius: 20px;
    opacity: 0.800000011920929;
    background: linear-gradient(132deg, #2B3A52 0%, #2B3A52 100%);
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
}

.aboutmanaging-section .img-heading{
    font-family: 'Vazirmatn';
    font-size: 60px;
    font-style: normal;
    font-weight: 100;
    line-height: 50px;
    text-align: left;
}
.aboutmanaging-section .img-sub-heading{
    color: #FFF;
    font-family: Vazirmatn;
    font-size: 40px;
    font-style: normal;
    font-weight: 100;
    line-height: normal;
    text-align: left;
}
.aboutmanaging-section .img-para{
    
    color: #EDF1F5;
    text-align: justify;
    font-family: Vazirmatn;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-bottom: 20px;
    transform: translateY(40px);
    text-align: justify !important;
}


.aboutmanaging-section .leftbottom {
    position: absolute;
    left: 50px;
    bottom: 0px;

}

.aboutmanaging-section .righttop {
    position: absolute;
    right: 0px;
    top: 30px;
}



@media only screen   and (min-device-width : 320px)  and (max-device-width : 767px)  { 
    
    .aboutmanaging-section {
        padding: 50px 25px;
    }

    .aboutmanaging-section .righttop {
        top: 0px;
    }

    .aboutmanaging-section .leftbottom {
        left: 0px;
    }

    .aboutmanaging-section .img-text {
        padding: 25px;
        margin-bottom: 0px;
    }
    
.aboutmanaging-section .img-heading{
    font-size: 36px;
    line-height: normal;
}

.aboutmanaging-section .img-sub-heading{
    font-size: 24px;
    font-weight: 100;
    line-height: normal;
}

.aboutmanaging-section .img-para{
    
    text-align: justify;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding-left: 30px;
    margin-bottom: 40px;
}

.aboutmanaging-section.ar .img-para{    
    padding-left: 0px;
}

}


@media only screen   and (min-device-width : 768px)  and (max-device-width : 1023px)  { 
    
    .aboutmanaging-section {
        padding: 50px 25px;
    }

    .aboutmanaging-section .desktop {
        display: none;
    }

    .aboutmanaging-section .mobile {
        display: block;
    }

    .aboutmanaging-section .righttop {
        top: 0px;
    }
    
    .aboutmanaging-section .leftbottom {
        left: 0px;
    }

    .aboutmanaging-section .img-text {
        padding: 25px;
        margin-bottom: 0px;
        max-width: 720px;
    }
    
    .aboutmanaging-section .img-heading{
        font-size: 36px;
        line-height: normal;
    }

    .aboutmanaging-section .img-sub-heading{
        font-size: 24px;
        font-weight: 100;
        line-height: normal;
    }

    .aboutmanaging-section .img-para{
        
        text-align: justify;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        padding-left: 30px;
        margin-bottom: 40px;
    }

}


/* @media only screen   and (min-device-width : 320px)  and (max-device-width : 480px)  { 
    .aboutmanaging-section .img-para { 
        padding-left: 0px;
    }
} */



@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1600px)  { 
    .aboutmanaging-section .righttop img,
    .aboutmanaging-section .leftbottom img {
        width: 70%;
    }
   
    .aboutmanaging-section .righttop {
        text-align: right;
    }
   
    .aboutmanaging-section .leftbottom {
        text-align: left;
    }

}

@media only screen   and (min-device-width : 1024px)  and (max-device-width : 1280px)  { 
    .aboutmanaging-section .righttop img,
    .aboutmanaging-section .leftbottom img {
        width: 50%;
    }
}

@media only screen   and (min-device-width : 1281px)  and (max-device-width : 1360px)  { 
    .aboutmanaging-section .righttop img,
    .aboutmanaging-section .leftbottom img {
        width: 60%;
    }
}